import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RadioComponent } from './components/radio/radio.component';
import { TVComponent } from './components/tv/tv.component';
import { PodcastComponent } from './components/podcast/podcast.component';
import { NewsComponent } from './components/news/news.component';
import { AppComponent } from './app.component';



const routes: Routes = [
{path: '', component: RadioComponent},
{path: 'Radio', component: RadioComponent},
{path: 'TV', component: TVComponent},
{path: 'Podcast', component: PodcastComponent},
// { path: 'News', component: RadioComponent},


{
  path: 'News',
  children: [
    { path: '', component: RadioComponent },
    { path: ':id', component: RadioComponent }
  ]
},
{
  path: 'Radio',
  children: [
    { path: '', component: RadioComponent },
    { path: ':id', component: RadioComponent }
  ]
},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
