export class Feed {
    name: string;
    title: string;
    country: string;
    image: string;
    date: string;
    order: number;
    id: string;
    description: string;
    link: string;
}
