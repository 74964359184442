export class Radio {
    displayName: string;
    name: string;
    country: string;
    streamUrl: string;
    urlLogo: string;
    image: string;
    order: number;
    id: string;
    description: string;
}
