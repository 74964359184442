import { Component, OnInit } from '@angular/core';
import { Http, Response} from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Feed } from '../../models/feed';
declare var require: any;
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})


export class NewsComponent implements OnInit {

  constructor(
    protected http: HttpClient) {
    // super( http);
    }

  data = null;
  feeds: Feed[] = [];

  ngOnInit() {
  const res =  this.http.get('https://cors-anywhere.herokuapp.com/' + 'http://www.diario.aw/feed/', {responseType: 'text'}).subscribe(
    response => {
      this.data = response;
      this.readXml(response);
     // const jsonText = JSON.stringify(this.xmlToJson(response));
     // const myObjRes = JSON.parse(response);
   },
    error => {
      //this.loading = false;
   });

  }

  readXml(res: string) {
   const result =  res.split('<title>');
   let i = 0;
   for (let j = 0; j < result.length; j++) {
     if (j > 1) {
      const result2 = result[j].split('</title>');
      const newFeed = new Feed();
      newFeed.title = result2[0];
      newFeed.id = i.toString();
      i = i + 1;
      this.feeds.push(newFeed);
     }
   }
  }

}
