import { Radio } from '../models/radio';


export const Radios: Radio[] = [

       {
               displayName: 'Radio Hit 94.1 FM ',
                   name: 'Radio-Hit-94.1-FM ',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=UCB1j0R4SBs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://hit94fm.serverroom.us:8490/;stream/1',
                   id: '100',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'We blow the competition away'
            },
       {
               displayName: 'The New Cool FM 98.9',
                   name: 'The-New-Cool-FM-98.9',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=1zsjSDS-78I%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://www.coolaruba.com:8000/;stream/1',
                   id: '101',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Hits 100.9 FM',
                   name: 'Hits-100.9-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CmpaUPJ0gD0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://209.9.238.26/stream',
                   id: '102',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Power 101.7 FM',
                   name: 'Power-101.7-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zMXe4_GMNso%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.81.62:8000/;stream/1',
                   id: '103',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'Show it to me'
            },
       {
               displayName: 'Massive',
                   name: 'Massive',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zRJzeGw8T_Y%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://shoutie.bitklik.com:8001/;stream/1',
                   id: '104',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'GFM 99.9 FM',
                   name: 'GFM-99.9-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=o8FstAvlTRo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://130.185.144.199:35687/;stream/1',
                   id: '105',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Fresh Fm 104.3',
                   name: 'Fresh-Fm-104.3',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=OpkcUSmhLa0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://www.fresharuba.com:8006/;stream/1',
                   id: '106',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'I love Aruba',
                   name: 'I-love-Aruba',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zFEfP38vZ_w%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://67.212.189.122:8119/;stream/1',
                   id: '107',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Blue FM',
                   name: 'Blue-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=iNXfUyHhizY%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.245.78.146:8014/;stream/1',
                   id: '108',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Radio Mania',
                   name: 'Radio-Mania',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=B3ezWT9QgG4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://62.210.209.179:8047/live',
                   id: '109',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pegate'
            },
       {
               displayName: 'Radio 737',
                   name: 'Radio-737',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dRLsDI1zUiE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.236.59.83:8240/;stream/1',
                   id: '110',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'The force of music'
            },
       {
               displayName: 'VIP Radio Romance',
                   name: 'VIP-Radio-Romance',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=PI44-hvEXmE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://192.241.254.172:8501/ch5',
                   id: '111',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Vida FM Aruba',
                   name: 'Vida-FM-Aruba',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=kNchwzxwKy4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://62.210.209.179:8020/live',
                   id: '112',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
           {
               displayName: 'Radio Mas 99.7 FM',
                   name: 'Radio-Mas-99.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CznuOllfxJg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mas99.westream.cloud:8000/mas99',
                   id: '301',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Mas 99.7 FM is the most listened to channel among the young people of Curaçao. The DJs present the daily programs in a fun and modern way. Radio Mas was established in December 2001. Since then it can be received on the 99.7 FM frequency. It is a commercial radio channel that radiates positivity. In addition to broadcasting the entertaining programs, the objective is also to be educational.'
            },
           {   displayName: 'Clazz 95.1 FM',
                   name: 'Clazz-95.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=uBDc0K4xMYo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8016/live',
                   id: '302',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Clazz FM is a radio station that is located on the Curacao. From this island it broadcasts the most beautiful Jazz music over the air. In addition, blues and more exotic music are also played. You can recognize the relaxed atmosphere of radio Curaçao on this channel. Enjoy the wonderful broadcasts 24/7 every day. Clazz FMs slogan is: "Not only the good music ... Just the best!" and "The stage for Curacao s leading musicians!"'
            },
           {   displayName: 'Radio Krioyo 89.7 FM',
                   name: 'Radio-Krioyo-89.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=n9jWTqsOs0w%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.245.78.146:8000/;stream.mp3',
                   id: '303',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Krioyo is a favorite channel among the local listeners of 30+ years. The reason is because the tunes played varies from older exotic music to more current Latin hits. But it is clear that this channel has not forgotten its traditional hits. And that is exactly where this channel got its popularity from. Where the competitors usually go with the choice of music in contemporary pop music, Radio Krioyo continues to stick to the traditions. And that is a unique under-capital. Thanks to this channel you can still listen to the older local music. The most used language is therefore Papiamento. The language most commonly spoken on Curacao.'
            },
           {   displayName: 'Radio Direct 107.1 FM',
                   name: 'Radio-Direct-107.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=VRNlljPfQoQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s9.voscast.com:7364/;stream/1',
                   id: '304',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Are you interested in the local news of Curacao and regional exotic music? Then it is best to tune into Radio Direct 107. All of Curacao enjoys the programs that can be heard on Direct 107 FM every day. The target group of this channel are young people between 18 and 35 years old. As far as music is concerned, it is mainly exotic South American oriented. The emphasis is on Caribbean music. From zouk to salsa and dancehall ... you can hear it all on this channel. Papiamento is the most spoken language on this channel. Direct 107 FM is the radio broadcaster of Curacao. Do you like music from the former Netherlands Antilles?'
            },
           {   displayName: 'Radio Hoyer 1 101.9 FM',
                   name: 'Radio-Hoyer-1',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=y8EnEeQgeeo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s10.voscast.com:9880/;',
                   id: '305',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Hoyer 1 has been a household name on the Caribbean islands for a long time. It has been in the top channels on the island of Curacao for years! To this day, it is a very popular channel. Especially on Curacao, where it has been established since its foundation. This channel has been around for more than half a century! It is a huge achievement that the channel has been entertaining the population for such a long time and provided them with fun, but especially useful programs. The channel was established in 1954. Radio Hoyer 1 was a fact. It could be listened through the FM and AM frequencies at the time. Currently the AM channel has been canceled and you can only listen via FM or the online webstream. The name owes the channel to the founder, Horacio Erasmo Hoyer, who started broadcasting on March 10, 1954.'
            },
           {   displayName: 'Radio Hoyer 2 105.1 FM ',
                   name: 'Radio-Hoyer-2',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=44buYZIE-lE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s10.voscast.com:9772/;',
                   id: '306',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Hoyer 2 is just as popular as Hoyer 1. “The sun is always shining!” is the slogan of this channel. The big difference is that Hoyer 2 is more internationally oriented than the Hoyer 1. Music such as Reggae, Dancehall and pop are broadcast on this channel. The history of the company behind this channel goes way back. Radio Hoyer was founded by a well-known athlete in Curaçao who’s name is Horacio Erasmo Hoyer. He started broadcasting on March 10, 1954.'
            },
           {   displayName: 'Radio Active 104.5 FM',
                   name: 'Radio-Active-104.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dtMtyZqZ1E0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://radioactive.shoutcaststream.com:8416/stream',
                   id: '307',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Active 104.5 FM Curaçao is a popular radio channel which is located on Curacao. Listening to Radio Active  you can enjoy cozy pop music with a South American touch. For example, do you like Jean Pauls music? Then you are at the right place at Radio Active. In addition to pleasant music, you can also enjoy entertaining radio programs here. By calling in you can participate in the entertaining programs. You can socialize with the presenter of the program or participate in the discussions. In addition to entertainment, the latest news can be heard during the information break. In particular, local news from the region is presented. You can listen to the news every hour during the information break. This is alternated with advertisements. “Korsou ta Dushi” is the slogan of the popular channel.'
            },
           {   displayName: 'Radio Deltha 92.7 FM',
                   name: 'Radio-Deltha-92.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=666f2uHXGko%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://radiodeltha927fm.primcast.com:6484/;',
                   id: '308',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Deltha FM is a radio channel situated in Willemstad Curaçao. This local radio station addresses political issues on the island and also topics related to religion, such as the Gospel. The local language Papiementu is spoken on this channel which locally is the most commonly used in addition to Dutch. Listeners can dial in over the phone to give their opinion or to participate in discussions about certain political or current issues.'
            },
           {   displayName: 'Radio Korsou  94.5 FM',
                   name: 'Radio-Korsou-94.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=yhk83vLOx_g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.243.94.61:8028/;',
                   id: '310',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Korsou is the voice of the inhabitants of the most beautiful island in the world. On Curacao you can listen to this channel live every day via the ether and the online live stream. You can tell from the name that Radio Korsou FM mainly broadcasts in the local language. Papiamento is the locally spoken language on Curacao. Tune in now to enjoy the tropical vibes of this radio station. Here you will enjoy the South American hits for salsa, merengue, reggaeton, but also for other well-known latin pop hits.'
            },
           {   displayName: 'Radio Korsou  94.5 FM',
                   name: 'Radio-Korsou-94.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=yhk83vLOx_g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.243.94.61:8028/;',
                   id: '311',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Korsou is the voice of the inhabitants of the most beautiful island in the world. On Curacao you can listen to this channel live every day via the ether and the online live stream. You can tell from the name that Radio Korsou FM mainly broadcasts in the local language. Papiamento is the locally spoken language on Curacao. Tune in now to enjoy the tropical vibes of this radio station. Here you will enjoy the South American hits for salsa, merengue, reggaeton, but also for other well-known latin pop hits.'
            },
           {   displayName: 'Radio Paradise 103.1 FM',
                   name: 'Radio-Paradise-103.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=PtSGTfvAvd4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://38.96.175.97:4210/;',
                   id: '312',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'The official language on Paradise FM Dutch and sometimes English is also spoken. Paradise FM is the radio station with 24 hours a day "The Sound of Curaçao"! The latest news, the best music and great prices! On frequency all over Curacao on 103.1 FM. Young people in particular and people between 30 and 55 years of age listen to the programs every day. All broadcasts are alternated with news every half hour, during which the local actuality issues are discussed. The programs start every day from 6 a.m. These can be heard until 7 p.m. There is a lot of interaction with the target group.'
            },
           {   displayName: 'HiT radio 91.5 FM',
                   name: 'HiT-radio-91.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=cdX15hdKOAs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://ocsp.comodoca.com/;',
                   id: '313',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Hitradio 915 is one of the youngest radio station of Curaçao. The music is always fun here. With this channel you can enjoy the latest musical hits. Enjoy the latest international pop hits, but also more local and South American music. The broadcast is being performed on Curaçao and can be also listened to on the 91.5 FM frequency.'
            },
           {   displayName: 'Dolfijn 97.5 FM',
                   name: 'Dolfijn-97.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=UTR1aQ_tFyg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://85.214.231.253:8004/;',
                   id: '314',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Dolfijn FM is one the largest and most popular radio channel in Curaçao. Especially among the younger population, this channel is listened to a lot. The working language on this channel is Dutch. The channel is known for broadcasting international hits. The biggest hits of Curaçao are broadcast daily. But also hits from the Netherlands, America and Latin America. can be heard here. In addition to music, you can also stay up to date with the latest news from the former Netherlands Antilles. In addition, programs are broadcast daily from 2 a.m. to 9 p.m. Time there is abbreviated as AST (Atlantic Standard Time). Listeners can participate in competitions by listening. With this they can win prizes. The prizes often consist of tickets for concerts, festivals, films, sports competitions and other events. Dolphin FM is broadcast from Willemstad Curaçao. '
            },
           {   displayName: 'Radio One  103.9 FM',
                   name: 'Radio-One-103.9-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=jy17nzx_Kek%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8004/live',
                   id: '315',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio One is a radio channel that focuses on the young people of the Caribbean island. This is reflected in the music genre that can be heard on this channel. Every day listeners can enjoy the latest dance and top 40 hits. This therefore mainly concerns the latest electro hits. So there are many famous DJs in the playlist. The slogan of this channel is the station for the next generation. Radio one Curacao was founded in 2002. The founder has estimated the music genre well. There are not many channels that focus purely on Dance and the top 40 genre. Every evening remixes are broadcast from famous DJs in the DJ show. You can tune in also via frequency 103.9 FM. In addition to broadcasts over the air, this channel is also active on social media. On the Facebook page, photos and films are shared from House and Dance parties. In addition, a live report is regularly made of festivals where the radio is present. The working language on this channel is English which connects to the younger target group.'
            },
           {   displayName: 'Easy 97.9 FM',
                   name: 'Easy-97.9-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=VOGzUdWEKvY%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8011/live',
                   id: '316',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Easy FM is a radio channel that can be received on Curaçao and Aruba via the Ether. The frequency of the channel is 97.9 FM. The channel is still growing and is doing everything it can to attract as many listeners as possible every year. That is why the channel has chosen to stream online as well as over the air. '
            },
           {   displayName: 'Radio New Song (RTNS) 96.5 FM',
                   name: 'Radio-New-Song',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=1-SwOM7w2X4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s7.voscast.com:8704/;',
                   id: '317',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio New Song (RTNS) is more than just a radio channel. It is an open community where everyone is welcome to listen. This community unites weekly, monthly, daily in the church. Christianity and the Gospel are central. In addition to radio, television is also produced by RTNS. RTNS stands for Radio Television New Song. Since 1980, this channel can be heard over the air. Thanks to the great success and many donations, they were later able to expand their services with a TV channel. The broadcasts takes place from the studio or live from the church. Live streams of meetings, prayers, festivities. Through the broadcasting creating awareness and sharing the word of God. With this they hope to grow as a community by finding more followers who follow the word of God. To listen, you can tune into the 96.5 FM frequency. The working language is Dutch, Papiamento, but sometimes English. RTNS also has a college called Skol Immanuel.'
            },
           {
                   displayName: 'Voz di Bonaire 94.7 FM',
                   name: 'Voz-di-Bonaire-94.7FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=roXCb-zX6tI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://159.203.190.86:8304/;stream.mp3',
                   id: '318',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Voz di Bonaire broadcasts'
            },
        {
                   displayName: 'Radio Antiano',
                   name: 'Radio-Antiano',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=7c0wlGWjLlA%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/radioantiano?mp=/1',
                   id: '319',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Romantic sounds',
                   name: 'Romantic-sounds',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CS6m89WOgnE%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/romanticsound?mp=/1',
                   id: '320',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Chilling Station',
                   name: 'Chilling-Staion',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=8IyQESwgyvI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mediaserv30.live-streams.nl:8090/stream',
                   id: '322',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Chillingstation ta dal Master!'
            },
           {
                   displayName: 'Bula bo Radio',
                   name: 'Bula-bo-raido',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=BFNkTi_d80A%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://109.169.23.22:12731/;stream.mp3',
                   id: '323',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Nos Nota Number 1 Pero Ta Nos Ta Esun!'
            },
       {
                   displayName: 'Comeback Radio',
                   name: 'Comback-Radio',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dqiKZKTAAi0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://74.50.122.103:7000/;stream/1',
                   id: '324',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pa amante di montuno: 24/7 dushi musika den bo orea'
            },
       {
                   displayName: 'True 4 Life',
                   name: 'True-4-life',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=KwxV1KBMjsU%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://213.202.241.199:9446/;stream/1',
                   id: '325',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Fever Pitch',
                   name: 'Fever-Pitch',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=F669NviYOhA%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mediaserv30.live-streams.nl:8097/;stream.mp3',
                   id: '326',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'ONE LOVE'
            },
           {
                   displayName: 'Voz di Bonaire 94.7 FM',
                   name: 'Voz-di-Bonaire-94.7FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=roXCb-zX6tI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://159.203.190.86:8304/;stream.mp3',
                   id: '200',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Voz di Bonaire broadcasts'
            },
           {
                   displayName: 'Mega Classics 99.5 FM',
                   name: 'Mega-Classics-99.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=jpgYi6ZvRho%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.80.21:9120/;stream/1',
                   id: '201',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Mega Classics is the sister station of the popular Mega Hit FM (101.1 Mhz) on Bonaire and started in February 2018 as an internet radio station. Here at Mega Classics you can listen to all the best Oldies FEEL THE MUSIC!'
            },
           {
                   displayName: 'Alpha 93.1 FM',
                   name: 'Alpha-93.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=o3MH6aHNRsg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s7.streammonster.com:8394/;stream/1',
                   id: '203',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'DESCRIPTON TO BE ADDED'
            },
       {
                   displayName: 'Radio Adventista Boneiru 91.1 FM',
                   name: 'Adventista-Boneiru-91.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=_3m9Czu2PJM%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://streaming.capasiete.com:7270/;stream/1',
                   id: '204',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Kompartiendo Zonido di Speransa ku henter Boneiru'
            },
       {
                   displayName: 'Live 99 FM',
                   name: 'Live-99-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=siqFrqEvClQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.80.220:8000/;stream.mp3;stream/1',
                   id: '205',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'E Radio 100% gusta i skucha!'
            },
       {
                   displayName: 'Mega Hit 101.1 FM',
                   name: 'Mega-Hit-101.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=tBIROPg70Uk%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://megaspice.primcast.com:5216/;stream/1',
                   id: '206',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Feel The Music!'
            },
       {
                   displayName: 'Radio Bon FM 102.7',
                   name: 'Radio-Bon-FM-102.7',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=AeEhRxmfQ4M%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://bonfm5.primcast.com:6482/;stream/1',
                   id: '207',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Radio Brokes 93.5 FM',
                   name: 'Radio-Brokes-93.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=16y1uTMrB-A%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://78.129.193 .82:21242/;stream/1',
                   id: '208',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: '1300 AM Voice Of St. Maarten (PJD2)',
                   name: '1300-AM-Voice-Of-St.Maarten',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=ljwHYvlLIN4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://199.189.111.28:8364/;stream/1',
                   id: '400',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'SOS Radio 95.9 FM',
                   name: 'SOS-Radio-95.9-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=hU1YbvpO4uE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://2stream.dyndns.org:8000/sosradio',
                   id: '401',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'WE ENJOY!The most listened to radio station on St.Maarten/St.Martin'
            },
       {
                   displayName: 'Oasis 96.3FM',
                   name: 'Oasis-96.3-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=_GmK3sCg2Wg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel5.mp3',
                   id: '402',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The softer side of St.Maarten'
            },
        {
                   displayName: 'Radio Pearl 98.1 FM',
                   name: 'Radio-Pearl-98.1-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=aZwszpvco00%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel6.mp3',
                   id: '403',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pearl 98.1 FM'
            },
       {
                   displayName: 'Radio Laser 101.1 FM',
                   name: 'Radio-Laser-101.1-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=5uEq0KiB9ic%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel7.mp3',
                   id: '404',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'X104.3 FM',
                   name: 'X104.3-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=5YxGiT3qbk8%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/1043.mp3',
                   id: '405',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Troppix 105.5 FM',
                   name: 'Troppix-105.5-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=QAGDc3577sk%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel2.mp3',
                   id: '406',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The sounds of the Caribbean'
            },
           {
                   displayName: 'Radio Small Island Massive',
                   name: 'Radio-Small-Island-Massive',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=vRl_8gGH-2g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://vousstream.com:8078/stream',
                   id: '407',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'Small Island with Massive Radio'
            },
       {
                   displayName: 'Hot 24/7 Sint Maarten',
                   name: 'Hot-24-7-Sint-Maarten',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=mairX8hIUKs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8095/;stream/1',
                   id: '408',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The planet\'s hottest music'
            },
           {
                   displayName: 'Gospel Beithel Online',
                   name: 'Gospel-Beithel-Online',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=qrHMYGcA464%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/radiobeithelgospel?mp=/1',
                   id: '700',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Radio reino di Dios',
                   name: 'Radio-reino-di-Dios',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=QX4gm6yGBqQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://proxy.live-streams.nl/radioreinodidios',
                   id: '701',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Gospel Star Radio',
                   name: 'Gospel-Star-Radio',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=sEMXA4Fyg_Q%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://eu5.fastcast4u.com:5093/;stream/1',
                   id: '702',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: 'Kaminda Kristu ta Señor'
            },
           ];

export const Radios5: Radio[] = [

       {
               displayName: 'Radio Hit 94.1 FM ',
                   name: 'Radio-Hit-94.1-FM ',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=UCB1j0R4SBs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://hit94fm.serverroom.us:8490/;stream/1',
                   id: '100',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'We blow the competition away'
            },
       {
               displayName: 'The New Cool FM 98.9',
                   name: 'The-New-Cool-FM-98.9',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=1zsjSDS-78I%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://www.coolaruba.com:8000/;stream/1',
                   id: '101',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Hits 100.9 FM',
                   name: 'Hits-100.9-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CmpaUPJ0gD0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://209.9.238.26/stream',
                   id: '102',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Power 101.7 FM',
                   name: 'Power-101.7-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zMXe4_GMNso%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.81.62:8000/;stream/1',
                   id: '103',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'Show it to me'
            },
       {
               displayName: 'Massive',
                   name: 'Massive',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zRJzeGw8T_Y%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://shoutie.bitklik.com:8001/;stream/1',
                   id: '104',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'GFM 99.9 FM',
                   name: 'GFM-99.9-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=o8FstAvlTRo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://130.185.144.199:35687/;stream/1',
                   id: '105',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Fresh Fm 104.3',
                   name: 'Fresh-Fm-104.3',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=OpkcUSmhLa0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://www.fresharuba.com:8006/;stream/1',
                   id: '106',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'I love Aruba',
                   name: 'I-love-Aruba',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zFEfP38vZ_w%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://67.212.189.122:8119/;stream/1',
                   id: '107',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Blue FM',
                   name: 'Blue-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=iNXfUyHhizY%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.245.78.146:8014/;stream/1',
                   id: '108',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Radio Mania',
                   name: 'Radio-Mania',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=B3ezWT9QgG4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://62.210.209.179:8047/live',
                   id: '109',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pegate'
            },
       {
               displayName: 'Radio 737',
                   name: 'Radio-737',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dRLsDI1zUiE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.236.59.83:8240/;stream/1',
                   id: '110',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'The force of music'
            },
       {
               displayName: 'VIP Radio Romance',
                   name: 'VIP-Radio-Romance',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=PI44-hvEXmE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://192.241.254.172:8501/ch5',
                   id: '111',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Vida FM Aruba',
                   name: 'Vida-FM-Aruba',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=kNchwzxwKy4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://62.210.209.179:8020/live',
                   id: '112',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
           {
               displayName: 'Radio Mas 99.7 FM',
                   name: 'Radio-Mas-99.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CznuOllfxJg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mas99.westream.cloud:8000/mas99',
                   id: '301',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Mas 99.7 FM is the most listened to channel among the young people of Curaçao. The DJs present the daily programs in a fun and modern way. Radio Mas was established in December 2001. Since then it can be received on the 99.7 FM frequency. It is a commercial radio channel that radiates positivity. In addition to broadcasting the entertaining programs, the objective is also to be educational.'
            },
           {   displayName: 'Clazz 95.1 FM',
                   name: 'Clazz-95.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=uBDc0K4xMYo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8016/live',
                   id: '302',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Clazz FM is a radio station that is located on the Curacao. From this island it broadcasts the most beautiful Jazz music over the air. In addition, blues and more exotic music are also played. You can recognize the relaxed atmosphere of radio Curaçao on this channel. Enjoy the wonderful broadcasts 24/7 every day. Clazz FMs slogan is: "Not only the good music ... Just the best!" and "The stage for Curacao s leading musicians!"'
            },
           {   displayName: 'Radio Krioyo 89.7 FM',
                   name: 'Radio-Krioyo-89.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=n9jWTqsOs0w%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.245.78.146:8000/;stream.mp3',
                   id: '303',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Krioyo is a favorite channel among the local listeners of 30+ years. The reason is because the tunes played varies from older exotic music to more current Latin hits. But it is clear that this channel has not forgotten its traditional hits. And that is exactly where this channel got its popularity from. Where the competitors usually go with the choice of music in contemporary pop music, Radio Krioyo continues to stick to the traditions. And that is a unique under-capital. Thanks to this channel you can still listen to the older local music. The most used language is therefore Papiamento. The language most commonly spoken on Curacao.'
            },
           {   displayName: 'Radio Direct 107.1 FM',
                   name: 'Radio-Direct-107.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=VRNlljPfQoQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s9.voscast.com:7364/;stream/1',
                   id: '304',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Are you interested in the local news of Curacao and regional exotic music? Then it is best to tune into Radio Direct 107. All of Curacao enjoys the programs that can be heard on Direct 107 FM every day. The target group of this channel are young people between 18 and 35 years old. As far as music is concerned, it is mainly exotic South American oriented. The emphasis is on Caribbean music. From zouk to salsa and dancehall ... you can hear it all on this channel. Papiamento is the most spoken language on this channel. Direct 107 FM is the radio broadcaster of Curacao. Do you like music from the former Netherlands Antilles?'
            },
           {   displayName: 'Radio Hoyer 1 101.9 FM',
                   name: 'Radio-Hoyer-1',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=y8EnEeQgeeo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s10.voscast.com:9880/;',
                   id: '305',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Hoyer 1 has been a household name on the Caribbean islands for a long time. It has been in the top channels on the island of Curacao for years! To this day, it is a very popular channel. Especially on Curacao, where it has been established since its foundation. This channel has been around for more than half a century! It is a huge achievement that the channel has been entertaining the population for such a long time and provided them with fun, but especially useful programs. The channel was established in 1954. Radio Hoyer 1 was a fact. It could be listened through the FM and AM frequencies at the time. Currently the AM channel has been canceled and you can only listen via FM or the online webstream. The name owes the channel to the founder, Horacio Erasmo Hoyer, who started broadcasting on March 10, 1954.'
            },
           {   displayName: 'Radio Hoyer 2 105.1 FM ',
                   name: 'Radio-Hoyer-2',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=44buYZIE-lE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s10.voscast.com:9772/;',
                   id: '306',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Hoyer 2 is just as popular as Hoyer 1. “The sun is always shining!” is the slogan of this channel. The big difference is that Hoyer 2 is more internationally oriented than the Hoyer 1. Music such as Reggae, Dancehall and pop are broadcast on this channel. The history of the company behind this channel goes way back. Radio Hoyer was founded by a well-known athlete in Curaçao who’s name is Horacio Erasmo Hoyer. He started broadcasting on March 10, 1954.'
            },
           {   displayName: 'Radio Active 104.5 FM',
                   name: 'Radio-Active-104.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dtMtyZqZ1E0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://radioactive.shoutcaststream.com:8416/stream',
                   id: '307',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Active 104.5 FM Curaçao is a popular radio channel which is located on Curacao. Listening to Radio Active  you can enjoy cozy pop music with a South American touch. For example, do you like Jean Pauls music? Then you are at the right place at Radio Active. In addition to pleasant music, you can also enjoy entertaining radio programs here. By calling in you can participate in the entertaining programs. You can socialize with the presenter of the program or participate in the discussions. In addition to entertainment, the latest news can be heard during the information break. In particular, local news from the region is presented. You can listen to the news every hour during the information break. This is alternated with advertisements. “Korsou ta Dushi” is the slogan of the popular channel.'
            },
           {   displayName: 'Radio Deltha 92.7 FM',
                   name: 'Radio-Deltha-92.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=666f2uHXGko%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://radiodeltha927fm.primcast.com:6484/;',
                   id: '308',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Deltha FM is a radio channel situated in Willemstad Curaçao. This local radio station addresses political issues on the island and also topics related to religion, such as the Gospel. The local language Papiementu is spoken on this channel which locally is the most commonly used in addition to Dutch. Listeners can dial in over the phone to give their opinion or to participate in discussions about certain political or current issues.'
            },
           {   displayName: 'Radio Korsou  94.5 FM',
                   name: 'Radio-Korsou-94.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=yhk83vLOx_g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.243.94.61:8028/;',
                   id: '310',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Korsou is the voice of the inhabitants of the most beautiful island in the world. On Curacao you can listen to this channel live every day via the ether and the online live stream. You can tell from the name that Radio Korsou FM mainly broadcasts in the local language. Papiamento is the locally spoken language on Curacao. Tune in now to enjoy the tropical vibes of this radio station. Here you will enjoy the South American hits for salsa, merengue, reggaeton, but also for other well-known latin pop hits.'
            },
           {   displayName: 'Radio Korsou  94.5 FM',
                   name: 'Radio-Korsou-94.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=yhk83vLOx_g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.243.94.61:8028/;',
                   id: '311',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Korsou is the voice of the inhabitants of the most beautiful island in the world. On Curacao you can listen to this channel live every day via the ether and the online live stream. You can tell from the name that Radio Korsou FM mainly broadcasts in the local language. Papiamento is the locally spoken language on Curacao. Tune in now to enjoy the tropical vibes of this radio station. Here you will enjoy the South American hits for salsa, merengue, reggaeton, but also for other well-known latin pop hits.'
            },
           {   displayName: 'Radio Paradise 103.1 FM',
                   name: 'Radio-Paradise-103.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=PtSGTfvAvd4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://38.96.175.97:4210/;',
                   id: '312',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'The official language on Paradise FM Dutch and sometimes English is also spoken. Paradise FM is the radio station with 24 hours a day "The Sound of Curaçao"! The latest news, the best music and great prices! On frequency all over Curacao on 103.1 FM. Young people in particular and people between 30 and 55 years of age listen to the programs every day. All broadcasts are alternated with news every half hour, during which the local actuality issues are discussed. The programs start every day from 6 a.m. These can be heard until 7 p.m. There is a lot of interaction with the target group.'
            },
           {   displayName: 'HiT radio 91.5 FM',
                   name: 'HiT-radio-91.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=cdX15hdKOAs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://ocsp.comodoca.com/;',
                   id: '313',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Hitradio 915 is one of the youngest radio station of Curaçao. The music is always fun here. With this channel you can enjoy the latest musical hits. Enjoy the latest international pop hits, but also more local and South American music. The broadcast is being performed on Curaçao and can be also listened to on the 91.5 FM frequency.'
            },
           {   displayName: 'Dolfijn 97.5 FM',
                   name: 'Dolfijn-97.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=UTR1aQ_tFyg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://85.214.231.253:8004/;',
                   id: '314',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Dolfijn FM is one the largest and most popular radio channel in Curaçao. Especially among the younger population, this channel is listened to a lot. The working language on this channel is Dutch. The channel is known for broadcasting international hits. The biggest hits of Curaçao are broadcast daily. But also hits from the Netherlands, America and Latin America. can be heard here. In addition to music, you can also stay up to date with the latest news from the former Netherlands Antilles. In addition, programs are broadcast daily from 2 a.m. to 9 p.m. Time there is abbreviated as AST (Atlantic Standard Time). Listeners can participate in competitions by listening. With this they can win prizes. The prizes often consist of tickets for concerts, festivals, films, sports competitions and other events. Dolphin FM is broadcast from Willemstad Curaçao. '
            },
           {   displayName: 'Radio One  103.9 FM',
                   name: 'Radio-One-103.9-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=jy17nzx_Kek%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8004/live',
                   id: '315',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio One is a radio channel that focuses on the young people of the Caribbean island. This is reflected in the music genre that can be heard on this channel. Every day listeners can enjoy the latest dance and top 40 hits. This therefore mainly concerns the latest electro hits. So there are many famous DJs in the playlist. The slogan of this channel is the station for the next generation. Radio one Curacao was founded in 2002. The founder has estimated the music genre well. There are not many channels that focus purely on Dance and the top 40 genre. Every evening remixes are broadcast from famous DJs in the DJ show. You can tune in also via frequency 103.9 FM. In addition to broadcasts over the air, this channel is also active on social media. On the Facebook page, photos and films are shared from House and Dance parties. In addition, a live report is regularly made of festivals where the radio is present. The working language on this channel is English which connects to the younger target group.'
            },
           {   displayName: 'Easy 97.9 FM',
                   name: 'Easy-97.9-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=VOGzUdWEKvY%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8011/live',
                   id: '316',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Easy FM is a radio channel that can be received on Curaçao and Aruba via the Ether. The frequency of the channel is 97.9 FM. The channel is still growing and is doing everything it can to attract as many listeners as possible every year. That is why the channel has chosen to stream online as well as over the air. '
            },
           {   displayName: 'Radio New Song (RTNS) 96.5 FM',
                   name: 'Radio-New-Song',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=1-SwOM7w2X4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s7.voscast.com:8704/;',
                   id: '317',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio New Song (RTNS) is more than just a radio channel. It is an open community where everyone is welcome to listen. This community unites weekly, monthly, daily in the church. Christianity and the Gospel are central. In addition to radio, television is also produced by RTNS. RTNS stands for Radio Television New Song. Since 1980, this channel can be heard over the air. Thanks to the great success and many donations, they were later able to expand their services with a TV channel. The broadcasts takes place from the studio or live from the church. Live streams of meetings, prayers, festivities. Through the broadcasting creating awareness and sharing the word of God. With this they hope to grow as a community by finding more followers who follow the word of God. To listen, you can tune into the 96.5 FM frequency. The working language is Dutch, Papiamento, but sometimes English. RTNS also has a college called Skol Immanuel.'
            },
           {
                   displayName: 'Voz di Bonaire 94.7 FM',
                   name: 'Voz-di-Bonaire-94.7FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=roXCb-zX6tI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://159.203.190.86:8304/;stream.mp3',
                   id: '318',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Voz di Bonaire broadcasts'
            },
        {
                   displayName: 'Radio Antiano',
                   name: 'Radio-Antiano',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=7c0wlGWjLlA%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/radioantiano?mp=/1',
                   id: '319',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Romantic sounds',
                   name: 'Romantic-sounds',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CS6m89WOgnE%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/romanticsound?mp=/1',
                   id: '320',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Chilling Station',
                   name: 'Chilling-Staion',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=8IyQESwgyvI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mediaserv30.live-streams.nl:8090/stream',
                   id: '322',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Chillingstation ta dal Master!'
            },
           {
                   displayName: 'Bula bo Radio',
                   name: 'Bula-bo-raido',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=BFNkTi_d80A%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://109.169.23.22:12731/;stream.mp3',
                   id: '323',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Nos Nota Number 1 Pero Ta Nos Ta Esun!'
            },
       {
                   displayName: 'Comeback Radio',
                   name: 'Comback-Radio',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dqiKZKTAAi0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://74.50.122.103:7000/;stream/1',
                   id: '324',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pa amante di montuno: 24/7 dushi musika den bo orea'
            },
       {
                   displayName: 'True 4 Life',
                   name: 'True-4-life',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=KwxV1KBMjsU%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://213.202.241.199:9446/;stream/1',
                   id: '325',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Fever Pitch',
                   name: 'Fever-Pitch',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=F669NviYOhA%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mediaserv30.live-streams.nl:8097/;stream.mp3',
                   id: '325',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'ONE LOVE'
            },
           {
                   displayName: 'Voz di Bonaire 94.7 FM',
                   name: 'Voz-di-Bonaire-94.7FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=roXCb-zX6tI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://159.203.190.86:8304/;stream.mp3',
                   id: '200',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Voz di Bonaire broadcasts'
            },
           {
                   displayName: 'Mega Classics 99.5 FM',
                   name: 'Mega-Classics-99.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=jpgYi6ZvRho%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.80.21:9120/;stream/1',
                   id: '201',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Mega Classics is the sister station of the popular Mega Hit FM (101.1 Mhz) on Bonaire and started in February 2018 as an internet radio station. Here at Mega Classics you can listen to all the best Oldies FEEL THE MUSIC!'
            },
           {
                   displayName: 'Alpha 93.1 FM',
                   name: 'Alpha-93.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=o3MH6aHNRsg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s7.streammonster.com:8394/;stream/1',
                   id: '203',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'DESCRIPTON TO BE ADDED'
            },
       {
                   displayName: 'Radio Adventista Boneiru 91.1 FM',
                   name: 'Adventista-Boneiru-91.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=_3m9Czu2PJM%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://streaming.capasiete.com:7270/;stream/1',
                   id: '204',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Kompartiendo Zonido di Speransa ku henter Boneiru'
            },
       {
                   displayName: 'Live 99 FM',
                   name: 'Live-99-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=siqFrqEvClQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.80.220:8000/;stream.mp3;stream/1',
                   id: '205',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'E Radio 100% gusta i skucha!'
            },
       {
                   displayName: 'Mega Hit 101.1 FM',
                   name: 'Mega-Hit-101.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=tBIROPg70Uk%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://megaspice.primcast.com:5216/;stream/1',
                   id: '206',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Feel The Music!'
            },
       {
                   displayName: 'Radio Bon FM 102.7',
                   name: 'Radio-Bon-FM-102.7',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=AeEhRxmfQ4M%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://bonfm5.primcast.com:6482/;stream/1',
                   id: '207',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Radio Brokes 93.5 FM',
                   name: 'Radio-Brokes-93.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=16y1uTMrB-A%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://78.129.193 .82:21242/;stream/1',
                   id: '208',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: '1300 AM Voice Of St. Maarten (PJD2)',
                   name: '1300-AM-Voice-Of-St.Maarten',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=ljwHYvlLIN4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://199.189.111.28:8364/;stream/1',
                   id: '400',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'SOS Radio 95.9 FM',
                   name: 'SOS-Radio-95.9-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=hU1YbvpO4uE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://2stream.dyndns.org:8000/sosradio',
                   id: '401',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'WE ENJOY!The most listened to radio station on St.Maarten/St.Martin'
            },
       {
                   displayName: 'Oasis 96.3FM',
                   name: 'Oasis-96.3-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=_GmK3sCg2Wg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel5.mp3',
                   id: '402',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The softer side of St.Maarten'
            },
        {
                   displayName: 'Radio Pearl 98.1 FM',
                   name: 'Radio-Pearl-98.1-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=aZwszpvco00%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel6.mp3',
                   id: '403',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pearl 98.1 FM'
            },
       {
                   displayName: 'Radio Laser 101.1 FM',
                   name: 'Radio-Laser-101.1-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=5uEq0KiB9ic%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel7.mp3',
                   id: '404',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'X104.3 FM',
                   name: 'X104.3-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=5YxGiT3qbk8%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/1043.mp3',
                   id: '405',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Troppix 105.5 FM',
                   name: 'Troppix-105.5-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=QAGDc3577sk%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel2.mp3',
                   id: '406',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The sounds of the Caribbean'
            },
           {
                   displayName: 'Radio Small Island Massive',
                   name: 'Radio-Small-Island-Massive',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=vRl_8gGH-2g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://vousstream.com:8078/stream',
                   id: '407',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'Small Island with Massive Radio'
            },
       {
                   displayName: 'Hot 24/7 Sint Maarten',
                   name: 'Hot-24-7-Sint-Maarten',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=mairX8hIUKs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8095/;stream/1',
                   id: '408',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The planet\'s hottest music'
            },
           {
                   displayName: 'Gospel Beithel Online',
                   name: 'Gospel-Beithel-Online',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=qrHMYGcA464%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/radiobeithelgospel?mp=/1',
                   id: '700',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Radio reino di Dios',
                   name: 'Radio-reino-di-Dios',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=QX4gm6yGBqQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://proxy.live-streams.nl/radioreinodidios',
                   id: '701',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Gospel Star Radio',
                   name: 'Gospel-Star-Radio',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=sEMXA4Fyg_Q%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://eu5.fastcast4u.com:5093/;stream/1',
                   id: '702',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: 'Kaminda Kristu ta Señor'
            },
           ];


export const RadiosOlder: Radio[] = [

       {
               displayName: 'Radio Hit 94.1 FM ',
                   name: 'Radio-Hit-94.1-FM ',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=UCB1j0R4SBs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://hit94fm.serverroom.us:8490/;stream/1',
                   id: '100',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'We blow the competition away'
            },
       {
               displayName: 'The New Cool FM 98.9',
                   name: 'The-New-Cool-FM-98.9',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=1zsjSDS-78I%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://www.coolaruba.com:8000/;stream/1',
                   id: '101',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Hits 100.9 FM',
                   name: 'Hits-100.9-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CmpaUPJ0gD0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://209.9.238.26/stream',
                   id: '102',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Power 101.7 FM',
                   name: 'Power-101.7-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zMXe4_GMNso%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.81.62:8000/;stream/1',
                   id: '103',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'Show it to me'
            },
       {
               displayName: 'Massive',
                   name: 'Massive',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zRJzeGw8T_Y%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://shoutie.bitklik.com:8001/;stream/1',
                   id: '104',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'GFM 99.9 FM',
                   name: 'GFM-99.9-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=o8FstAvlTRo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://130.185.144.199:35687/;stream/1',
                   id: '105',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Fresh Fm 104.3',
                   name: 'Fresh-Fm-104.3',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=OpkcUSmhLa0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://www.fresharuba.com:8006/;stream/1',
                   id: '106',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'I love Aruba',
                   name: 'I-love-Aruba',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zFEfP38vZ_w%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://67.212.189.122:8119/;stream/1',
                   id: '107',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Blue FM',
                   name: 'Blue-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=iNXfUyHhizY%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.245.78.146:8014/;stream/1',
                   id: '108',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Radio Mania',
                   name: 'Radio-Mania',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=B3ezWT9QgG4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://62.210.209.179:8047/live',
                   id: '109',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pegate'
            },
       {
               displayName: 'Radio 737',
                   name: 'Radio-737',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dRLsDI1zUiE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.236.59.83:8240/;stream/1',
                   id: '110',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'The force of music'
            },
       {
               displayName: 'VIP Radio Romance',
                   name: 'VIP-Radio-Romance',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=PI44-hvEXmE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://192.241.254.172:8501/ch5',
                   id: '111',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Vida FM Aruba',
                   name: 'Vida-FM-Aruba',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=kNchwzxwKy4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://62.210.209.179:8020/live',
                   id: '112',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
           {
               displayName: 'Radio Mas 99.7 FM',
                   name: 'Radio-Mas-99.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CznuOllfxJg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mas99.westream.cloud:8000/mas99',
                   id: '301',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Mas 99.7 FM is the most listened to channel among the young people of Curaçao. The DJs present the daily programs in a fun and modern way. Radio Mas was established in December 2001. Since then it can be received on the 99.7 FM frequency. It is a commercial radio channel that radiates positivity. In addition to broadcasting the entertaining programs, the objective is also to be educational.'
            },
           {   displayName: 'Clazz 95.1 FM',
                   name: 'Clazz-95.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=uBDc0K4xMYo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8016/live',
                   id: '302',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Clazz FM is a radio station that is located on the Curacao. From this island it broadcasts the most beautiful Jazz music over the air. In addition, blues and more exotic music are also played. You can recognize the relaxed atmosphere of radio Curaçao on this channel. Enjoy the wonderful broadcasts 24/7 every day. Clazz FMs slogan is: "Not only the good music ... Just the best!" and "The stage for Curacao s leading musicians!"'
            },
           {   displayName: 'Radio Krioyo 89.7 FM',
                   name: 'Radio-Krioyo-89.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=n9jWTqsOs0w%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.245.78.146:8000/;stream.mp3',
                   id: '303',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Krioyo is a favorite channel among the local listeners of 30+ years. The reason is because the tunes played varies from older exotic music to more current Latin hits. But it is clear that this channel has not forgotten its traditional hits. And that is exactly where this channel got its popularity from. Where the competitors usually go with the choice of music in contemporary pop music, Radio Krioyo continues to stick to the traditions. And that is a unique under-capital. Thanks to this channel you can still listen to the older local music. The most used language is therefore Papiamento. The language most commonly spoken on Curacao.'
            },
           {   displayName: 'Radio Direct 107.1 FM',
                   name: 'Radio-Direct-107.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=VRNlljPfQoQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s9.voscast.com:7364/;stream/1',
                   id: '304',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Are you interested in the local news of Curacao and regional exotic music? Then it is best to tune into Radio Direct 107. All of Curacao enjoys the programs that can be heard on Direct 107 FM every day. The target group of this channel are young people between 18 and 35 years old. As far as music is concerned, it is mainly exotic South American oriented. The emphasis is on Caribbean music. From zouk to salsa and dancehall ... you can hear it all on this channel. Papiamento is the most spoken language on this channel. Direct 107 FM is the radio broadcaster of Curacao. Do you like music from the former Netherlands Antilles?'
            },
           {   displayName: 'Radio Hoyer 1 101.9 FM',
                   name: 'Radio-Hoyer-1',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=y8EnEeQgeeo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s10.voscast.com:9880/;',
                   id: '305',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Hoyer 1 has been a household name on the Caribbean islands for a long time. It has been in the top channels on the island of Curacao for years! To this day, it is a very popular channel. Especially on Curacao, where it has been established since its foundation. This channel has been around for more than half a century! It is a huge achievement that the channel has been entertaining the population for such a long time and provided them with fun, but especially useful programs. The channel was established in 1954. Radio Hoyer 1 was a fact. It could be listened through the FM and AM frequencies at the time. Currently the AM channel has been canceled and you can only listen via FM or the online webstream. The name owes the channel to the founder, Horacio Erasmo Hoyer, who started broadcasting on March 10, 1954.'
            },
           {   displayName: 'Radio Hoyer 2 105.1 FM ',
                   name: 'Radio-Hoyer-2',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=44buYZIE-lE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s10.voscast.com:9772/;',
                   id: '306',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Hoyer 2 is just as popular as Hoyer 1. “The sun is always shining!” is the slogan of this channel. The big difference is that Hoyer 2 is more internationally oriented than the Hoyer 1. Music such as Reggae, Dancehall and pop are broadcast on this channel. The history of the company behind this channel goes way back. Radio Hoyer was founded by a well-known athlete in Curaçao who’s name is Horacio Erasmo Hoyer. He started broadcasting on March 10, 1954.'
            },
           {   displayName: 'Radio Active 104.5 FM',
                   name: 'Radio-Active-104.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dtMtyZqZ1E0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://radioactive.shoutcaststream.com:8416/stream',
                   id: '307',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Active 104.5 FM Curaçao is a popular radio channel which is located on Curacao. Listening to Radio Active  you can enjoy cozy pop music with a South American touch. For example, do you like Jean Pauls music? Then you are at the right place at Radio Active. In addition to pleasant music, you can also enjoy entertaining radio programs here. By calling in you can participate in the entertaining programs. You can socialize with the presenter of the program or participate in the discussions. In addition to entertainment, the latest news can be heard during the information break. In particular, local news from the region is presented. You can listen to the news every hour during the information break. This is alternated with advertisements. “Korsou ta Dushi” is the slogan of the popular channel.'
            },
           {   displayName: 'Radio Deltha 92.7 FM',
                   name: 'Radio-Deltha-92.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=666f2uHXGko%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://radiodeltha927fm.primcast.com:6484/;',
                   id: '308',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Deltha FM is a radio channel situated in Willemstad Curaçao. This local radio station addresses political issues on the island and also topics related to religion, such as the Gospel. The local language Papiementu is spoken on this channel which locally is the most commonly used in addition to Dutch. Listeners can dial in over the phone to give their opinion or to participate in discussions about certain political or current issues.'
            },
           {   displayName: 'Z86 Curacao Radio',
                   name: 'Z86-Curacao-Radio',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=i1fKZ-PIPBQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://curom.cw/z86.html',
                   id: '309',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Z86 is a radio station that has been around for more than 80 years.  On the island of Curaçao you can receive this channel on the frequency 860 AM. Radio station Z86 has been broadcasting on this frequency since 1933. This makes it the oldest channel in Curaçao. The parent company of the channel has Curom Broadcasting. That is a media company that has multiple radio channels on Curaçao. Z86 is known for the mission that is different from the competition. The entire radio programming of this radio station is based on informing the local population. Radio Z86 AM is mainly known for the interesting talk shows addressing local or international news as the main subject. In addition to news, topics such as sports and religion are also discussed.'
            },
           {   displayName: 'Radio Korsou  94.5 FM',
                   name: 'Radio-Korsou-94.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=yhk83vLOx_g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.243.94.61:8028/;',
                   id: '310',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Korsou is the voice of the inhabitants of the most beautiful island in the world. On Curacao you can listen to this channel live every day via the ether and the online live stream. You can tell from the name that Radio Korsou FM mainly broadcasts in the local language. Papiamento is the locally spoken language on Curacao. Tune in now to enjoy the tropical vibes of this radio station. Here you will enjoy the South American hits for salsa, merengue, reggaeton, but also for other well-known latin pop hits.'
            },
           {   displayName: 'Radio Korsou  94.5 FM',
                   name: 'Radio-Korsou-94.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=yhk83vLOx_g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.243.94.61:8028/;',
                   id: '311',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Korsou is the voice of the inhabitants of the most beautiful island in the world. On Curacao you can listen to this channel live every day via the ether and the online live stream. You can tell from the name that Radio Korsou FM mainly broadcasts in the local language. Papiamento is the locally spoken language on Curacao. Tune in now to enjoy the tropical vibes of this radio station. Here you will enjoy the South American hits for salsa, merengue, reggaeton, but also for other well-known latin pop hits.'
            },
           {   displayName: 'Radio Paradise 103.1 FM',
                   name: 'Radio-Paradise-103.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=PtSGTfvAvd4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://38.96.175.97:4210/;',
                   id: '312',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'The official language on Paradise FM Dutch and sometimes English is also spoken. Paradise FM is the radio station with 24 hours a day "The Sound of Curaçao"! The latest news, the best music and great prices! On frequency all over Curacao on 103.1 FM. Young people in particular and people between 30 and 55 years of age listen to the programs every day. All broadcasts are alternated with news every half hour, during which the local actuality issues are discussed. The programs start every day from 6 a.m. These can be heard until 7 p.m. There is a lot of interaction with the target group.'
            },
           {   displayName: 'HiT radio 91.5 FM',
                   name: 'HiT-radio-91.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=cdX15hdKOAs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://ocsp.comodoca.com/;',
                   id: '313',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Hitradio 915 is one of the youngest radio station of Curaçao. The music is always fun here. With this channel you can enjoy the latest musical hits. Enjoy the latest international pop hits, but also more local and South American music. The broadcast is being performed on Curaçao and can be also listened to on the 91.5 FM frequency.'
            },
           {   displayName: 'Dolfijn 97.5 FM',
                   name: 'Dolfijn-97.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=UTR1aQ_tFyg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://85.214.231.253:8004/;',
                   id: '314',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Dolfijn FM is one the largest and most popular radio channel in Curaçao. Especially among the younger population, this channel is listened to a lot. The working language on this channel is Dutch. The channel is known for broadcasting international hits. The biggest hits of Curaçao are broadcast daily. But also hits from the Netherlands, America and Latin America. can be heard here. In addition to music, you can also stay up to date with the latest news from the former Netherlands Antilles. In addition, programs are broadcast daily from 2 a.m. to 9 p.m. Time there is abbreviated as AST (Atlantic Standard Time). Listeners can participate in competitions by listening. With this they can win prizes. The prizes often consist of tickets for concerts, festivals, films, sports competitions and other events. Dolphin FM is broadcast from Willemstad Curaçao. '
            },
           {   displayName: 'Radio One  103.9 FM',
                   name: 'Radio-One-103.9-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=jy17nzx_Kek%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8004/live',
                   id: '315',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio One is a radio channel that focuses on the young people of the Caribbean island. This is reflected in the music genre that can be heard on this channel. Every day listeners can enjoy the latest dance and top 40 hits. This therefore mainly concerns the latest electro hits. So there are many famous DJs in the playlist. The slogan of this channel is the station for the next generation. Radio one Curacao was founded in 2002. The founder has estimated the music genre well. There are not many channels that focus purely on Dance and the top 40 genre. Every evening remixes are broadcast from famous DJs in the DJ show. You can tune in also via frequency 103.9 FM. In addition to broadcasts over the air, this channel is also active on social media. On the Facebook page, photos and films are shared from House and Dance parties. In addition, a live report is regularly made of festivals where the radio is present. The working language on this channel is English which connects to the younger target group.'
            },
           {   displayName: 'Easy 97.9 FM',
                   name: 'Easy-97.9-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=VOGzUdWEKvY%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8011/live',
                   id: '316',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Easy FM is a radio channel that can be received on Curaçao and Aruba via the Ether. The frequency of the channel is 97.9 FM. The channel is still growing and is doing everything it can to attract as many listeners as possible every year. That is why the channel has chosen to stream online as well as over the air. '
            },
           {   displayName: 'Radio New Song (RTNS) 96.5 FM',
                   name: 'Radio-New-Song',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=1-SwOM7w2X4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s7.voscast.com:8704/;',
                   id: '317',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio New Song (RTNS) is more than just a radio channel. It is an open community where everyone is welcome to listen. This community unites weekly, monthly, daily in the church. Christianity and the Gospel are central. In addition to radio, television is also produced by RTNS. RTNS stands for Radio Television New Song. Since 1980, this channel can be heard over the air. Thanks to the great success and many donations, they were later able to expand their services with a TV channel. The broadcasts takes place from the studio or live from the church. Live streams of meetings, prayers, festivities. Through the broadcasting creating awareness and sharing the word of God. With this they hope to grow as a community by finding more followers who follow the word of God. To listen, you can tune into the 96.5 FM frequency. The working language is Dutch, Papiamento, but sometimes English. RTNS also has a college called Skol Immanuel.'
            },
           {
                   displayName: 'Voz di Bonaire 94.7 FM',
                   name: 'Voz-di-Bonaire-94.7FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=roXCb-zX6tI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://159.203.190.86:8304/;stream.mp3',
                   id: '318',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Voz di Bonaire broadcasts'
            },
        {
                   displayName: 'Radio Antiano',
                   name: 'Radio-Antiano',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=7c0wlGWjLlA%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/radioantiano?mp=/1',
                   id: '319',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Romantic sounds',
                   name: 'Romantic-sounds',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CS6m89WOgnE%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/romanticsound?mp=/1',
                   id: '320',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Chilling Station',
                   name: 'Chilling-Staion',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=8IyQESwgyvI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mediaserv30.live-streams.nl:8090/stream',
                   id: '322',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Chillingstation ta dal Master!'
            },
           {
                   displayName: 'Bula bo Radio',
                   name: 'Bula-bo-raido',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=BFNkTi_d80A%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://109.169.23.22:12731/;stream.mp3',
                   id: '323',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Nos Nota Number 1 Pero Ta Nos Ta Esun!'
            },
       {
                   displayName: 'Comeback Radio',
                   name: 'Comback-Radio',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dqiKZKTAAi0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://74.50.122.103:7000/;stream/1',
                   id: '324',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pa amante di montuno: 24/7 dushi musika den bo orea'
            },
       {
                   displayName: 'True 4 Life',
                   name: 'True-4-life',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=KwxV1KBMjsU%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://213.202.241.199:8058/;stream/1',
                   id: '325',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Fever Pitch',
                   name: 'Fever-Pitch',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=F669NviYOhA%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mediaserv30.live-streams.nl:8097/;stream.mp3',
                   id: '325',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'ONE LOVE'
            },
           {
                   displayName: 'Voz di Bonaire 94.7 FM',
                   name: 'Voz-di-Bonaire-94.7FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=roXCb-zX6tI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://159.203.190.86:8304/;stream.mp3',
                   id: '200',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Voz di Bonaire broadcasts'
            },
           {
                   displayName: 'Mega Classics 99.5 FM',
                   name: 'Mega-Classics-99.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=jpgYi6ZvRho%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.80.21:9120/;stream/1',
                   id: '201',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Mega Classics is the sister station of the popular Mega Hit FM (101.1 Mhz) on Bonaire and started in February 2018 as an internet radio station. Here at Mega Classics you can listen to all the best Oldies FEEL THE MUSIC!'
            },
           {
                   displayName: 'Alpha 93.1 FM',
                   name: 'Alpha-93.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=o3MH6aHNRsg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s7.streammonster.com:8394/;stream/1',
                   id: '203',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'DESCRIPTON TO BE ADDED'
            },
       {
                   displayName: 'Radio Adventista Boneiru 91.1 FM',
                   name: 'Adventista-Boneiru-91.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=_3m9Czu2PJM%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://streaming.capasiete.com:7270/;stream/1',
                   id: '204',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Kompartiendo Zonido di Speransa ku henter Boneiru'
            },
       {
                   displayName: 'Live 99 FM',
                   name: 'Live-99-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=siqFrqEvClQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.80.220:8000/;stream.mp3;stream/1',
                   id: '205',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'E Radio 100% gusta i skucha!'
            },
       {
                   displayName: 'Mega Hit 101.1 FM',
                   name: 'Mega-Hit-101.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=tBIROPg70Uk%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://megaspice.primcast.com:5216/;stream/1',
                   id: '206',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Feel The Music!'
            },
       {
                   displayName: 'Radio Bon FM 102.7',
                   name: 'Radio-Bon-FM-102.7',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=AeEhRxmfQ4M%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://bonfm5.primcast.com:6482/;stream/1',
                   id: '207',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Radio Brokes 93.5 FM',
                   name: 'Radio-Brokes-93.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=16y1uTMrB-A%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://78.129.193 .82:21242/;stream/1',
                   id: '208',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: '1300 AM Voice Of St. Maarten (PJD2)',
                   name: '1300-AM-Voice-Of-St.Maarten',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=ljwHYvlLIN4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://199.189.111.28:8364/;stream/1',
                   id: '400',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'SOS Radio 95.9 FM',
                   name: 'SOS-Radio-95.9-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=hU1YbvpO4uE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://2stream.dyndns.org:8000/sosradio',
                   id: '401',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'WE ENJOY!The most listened to radio station on St.Maarten/St.Martin'
            },
       {
                   displayName: 'Oasis 96.3FM',
                   name: 'Oasis-96.3-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=_GmK3sCg2Wg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel5.mp3',
                   id: '402',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The softer side of St.Maarten'
            },
        {
                   displayName: 'Radio Pearl 98.1 FM',
                   name: 'Radio-Pearl-98.1-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=aZwszpvco00%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel6.mp3',
                   id: '403',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pearl 98.1 FM'
            },
       {
                   displayName: 'Radio Laser 101.1 FM',
                   name: 'Radio-Laser-101.1-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=5uEq0KiB9ic%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel7.mp3',
                   id: '404',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'X104.3 FM',
                   name: 'X104.3-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=5YxGiT3qbk8%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/1043.mp3',
                   id: '405',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Troppix 105.5 FM',
                   name: 'Troppix-105.5-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=QAGDc3577sk%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel2.mp3',
                   id: '406',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The sounds of the Caribbean'
            },
           {
                   displayName: 'Radio Small Island Massive',
                   name: 'Radio-Small-Island-Massive',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=vRl_8gGH-2g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://vousstream.com:8078/stream',
                   id: '407',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'Small Island with Massive Radio'
            },
       {
                   displayName: 'Hot 24/7 Sint Maarten',
                   name: 'Hot-24-7-Sint-Maarten',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=mairX8hIUKs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8095/;stream/1',
                   id: '408',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The planet\'s hottest music'
            },
           {
                   displayName: 'Gospel Beithel Online',
                   name: 'Gospel-Beithel-Online',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=qrHMYGcA464%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/radiobeithelgospel?mp=/1',
                   id: '700',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Radio reino di Dios',
                   name: 'Radio-reino-di-Dios',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=QX4gm6yGBqQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://proxy.live-streams.nl/radioreinodidios',
                   id: '701',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Gospel Star Radio',
                   name: 'Gospel-Star-Radio',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=sEMXA4Fyg_Q%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://eu5.fastcast4u.com:5093/;stream/1',
                   id: '702',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: 'Kaminda Kristu ta Señor'
            },
           ];


export const Radios2: Radio[] = [

       {
               displayName: 'Radio Hit 94.1 FM ',
                   name: 'Radio-Hit-94.1-FM ',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=UCB1j0R4SBs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://hit94fm.serverroom.us:8490/;stream/1',
                   id: '100',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'We blow the competition away'
            },
       {
               displayName: 'The New Cool FM 98.9',
                   name: 'The-New-Cool-FM-98.9',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=1zsjSDS-78I%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://www.coolaruba.com:8000/;stream/1',
                   id: '101',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Hits 100.9 FM',
                   name: 'Hits-100.9-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CmpaUPJ0gD0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://209.9.238.26/stream',
                   id: '102',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Power 101.7 FM',
                   name: 'Power-101.7-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zMXe4_GMNso%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.81.62:8000/;stream/1',
                   id: '103',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'Show it to me'
            },
       {
               displayName: 'Massive',
                   name: 'Massive',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zRJzeGw8T_Y%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://shoutie.bitklik.com:8001/;stream/1',
                   id: '104',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'GFM 99.9 FM',
                   name: 'GFM-99.9-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=o8FstAvlTRo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://130.185.144.199:35687/;stream/1',
                   id: '105',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Fresh Fm 104.3',
                   name: 'Fresh-Fm-104.3',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=OpkcUSmhLa0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://www.fresharuba.com:8006/;stream/1',
                   id: '106',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'I love Aruba',
                   name: 'I-love-Aruba',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zFEfP38vZ_w%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://67.212.189.122:8119/;stream/1',
                   id: '107',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Blue FM',
                   name: 'Blue-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=iNXfUyHhizY%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.245.78.146:8014/;stream/1',
                   id: '108',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Radio Mania',
                   name: 'Radio-Mania',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=B3ezWT9QgG4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://62.210.209.179:8047/live',
                   id: '109',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pegate'
            },
       {
               displayName: 'Radio 737',
                   name: 'Radio-737',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dRLsDI1zUiE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.236.59.83:8240/;stream/1',
                   id: '110',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'The force of music'
            },
       {
               displayName: 'VIP Radio Romance',
                   name: 'VIP-Radio-Romance',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=PI44-hvEXmE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://192.241.254.172:8501/ch5',
                   id: '111',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Vida FM Aruba',
                   name: 'Vida-FM-Aruba',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=kNchwzxwKy4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://62.210.209.179:8020/live',
                   id: '112',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
           {
               displayName: 'Radio Mas 99.7 FM',
                   name: 'Radio-Mas-99.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CznuOllfxJg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mas99.westream.cloud:8000/mas99',
                   id: '301',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Mas 99.7 FM is the most listened to channel among the young people of Curaçao. The DJs present the daily programs in a fun and modern way. Radio Mas was established in December 2001. Since then it can be received on the 99.7 FM frequency. It is a commercial radio channel that radiates positivity. In addition to broadcasting the entertaining programs, the objective is also to be educational.'
            },
           {   displayName: 'Clazz 95.1 FM',
                   name: 'Clazz-95.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=uBDc0K4xMYo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8016/live',
                   id: '302',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Clazz FM is a radio station that is located on the Curacao. From this island it broadcasts the most beautiful Jazz music over the air. In addition, blues and more exotic music are also played. You can recognize the relaxed atmosphere of radio Curaçao on this channel. Enjoy the wonderful broadcasts 24/7 every day. Clazz FMs slogan is: "Not only the good music ... Just the best!" and "The stage for Curacao s leading musicians!"'
            },
           {   displayName: 'Radio Krioyo 89.7 FM',
                   name: 'Radio-Krioyo-89.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=n9jWTqsOs0w%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.245.78.146:8000/;stream.mp3',
                   id: '303',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Krioyo is a favorite channel among the local listeners of 30+ years. The reason is because the tunes played varies from older exotic music to more current Latin hits. But it is clear that this channel has not forgotten its traditional hits. And that is exactly where this channel got its popularity from. Where the competitors usually go with the choice of music in contemporary pop music, Radio Krioyo continues to stick to the traditions. And that is a unique under-capital. Thanks to this channel you can still listen to the older local music. The most used language is therefore Papiamento. The language most commonly spoken on Curacao.'
            },
           {   displayName: 'Radio Direct 107.1 FM',
                   name: 'Radio-Direct-107.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=VRNlljPfQoQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s9.voscast.com:7364/;stream/1',
                   id: '304',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Are you interested in the local news of Curacao and regional exotic music? Then it is best to tune into Radio Direct 107. All of Curacao enjoys the programs that can be heard on Direct 107 FM every day. The target group of this channel are young people between 18 and 35 years old. As far as music is concerned, it is mainly exotic South American oriented. The emphasis is on Caribbean music. From zouk to salsa and dancehall ... you can hear it all on this channel. Papiamento is the most spoken language on this channel. Direct 107 FM is the radio broadcaster of Curacao. Do you like music from the former Netherlands Antilles?'
            },
           {   displayName: 'Radio Hoyer 1 101.9 FM',
                   name: 'Radio-Hoyer-1',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=y8EnEeQgeeo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s10.voscast.com:9880/;',
                   id: '305',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Hoyer 1 has been a household name on the Caribbean islands for a long time. It has been in the top channels on the island of Curacao for years! To this day, it is a very popular channel. Especially on Curacao, where it has been established since its foundation. This channel has been around for more than half a century! It is a huge achievement that the channel has been entertaining the population for such a long time and provided them with fun, but especially useful programs. The channel was established in 1954. Radio Hoyer 1 was a fact. It could be listened through the FM and AM frequencies at the time. Currently the AM channel has been canceled and you can only listen via FM or the online webstream. The name owes the channel to the founder, Horacio Erasmo Hoyer, who started broadcasting on March 10, 1954.'
            },
           {   displayName: 'Radio Hoyer 2 105.1 FM ',
                   name: 'Radio-Hoyer-2',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=44buYZIE-lE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s10.voscast.com:9772/;',
                   id: '306',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Hoyer 2 is just as popular as Hoyer 1. “The sun is always shining!” is the slogan of this channel. The big difference is that Hoyer 2 is more internationally oriented than the Hoyer 1. Music such as Reggae, Dancehall and pop are broadcast on this channel. The history of the company behind this channel goes way back. Radio Hoyer was founded by a well-known athlete in Curaçao who’s name is Horacio Erasmo Hoyer. He started broadcasting on March 10, 1954.'
            },
           {   displayName: 'Radio Active 104.5 FM',
                   name: 'Radio-Active-104.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dtMtyZqZ1E0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://radioactive.shoutcaststream.com:8416/stream',
                   id: '307',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Active 104.5 FM Curaçao is a popular radio channel which is located on Curacao. Listening to Radio Active  you can enjoy cozy pop music with a South American touch. For example, do you like Jean Pauls music? Then you are at the right place at Radio Active. In addition to pleasant music, you can also enjoy entertaining radio programs here. By calling in you can participate in the entertaining programs. You can socialize with the presenter of the program or participate in the discussions. In addition to entertainment, the latest news can be heard during the information break. In particular, local news from the region is presented. You can listen to the news every hour during the information break. This is alternated with advertisements. “Korsou ta Dushi” is the slogan of the popular channel.'
            },
           {   displayName: 'Radio Deltha 92.7 FM',
                   name: 'Radio-Deltha-92.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=666f2uHXGko%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://radiodeltha927fm.primcast.com:6484/;',
                   id: '308',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Deltha FM is a radio channel situated in Willemstad Curaçao. This local radio station addresses political issues on the island and also topics related to religion, such as the Gospel. The local language Papiementu is spoken on this channel which locally is the most commonly used in addition to Dutch. Listeners can dial in over the phone to give their opinion or to participate in discussions about certain political or current issues.'
            },
           {   displayName: 'Z86 Curacao Radio',
                   name: 'Z86-Curacao-Radio',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=i1fKZ-PIPBQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://curom.cw/z86.html',
                   id: '309',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Z86 is a radio station that has been around for more than 80 years.  On the island of Curaçao you can receive this channel on the frequency 860 AM. Radio station Z86 has been broadcasting on this frequency since 1933. This makes it the oldest channel in Curaçao. The parent company of the channel has Curom Broadcasting. That is a media company that has multiple radio channels on Curaçao. Z86 is known for the mission that is different from the competition. The entire radio programming of this radio station is based on informing the local population. Radio Z86 AM is mainly known for the interesting talk shows addressing local or international news as the main subject. In addition to news, topics such as sports and religion are also discussed.'
            },
           {   displayName: 'Radio Korsou  94.5 FM',
                   name: 'Radio-Korsou-94.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=yhk83vLOx_g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.243.94.61:8028/;',
                   id: '310',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Korsou is the voice of the inhabitants of the most beautiful island in the world. On Curacao you can listen to this channel live every day via the ether and the online live stream. You can tell from the name that Radio Korsou FM mainly broadcasts in the local language. Papiamento is the locally spoken language on Curacao. Tune in now to enjoy the tropical vibes of this radio station. Here you will enjoy the South American hits for salsa, merengue, reggaeton, but also for other well-known latin pop hits.'
            },
           {   displayName: 'Radio Korsou  94.5 FM',
                   name: 'Radio-Korsou-94.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=yhk83vLOx_g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.243.94.61:8028/;',
                   id: '311',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Korsou is the voice of the inhabitants of the most beautiful island in the world. On Curacao you can listen to this channel live every day via the ether and the online live stream. You can tell from the name that Radio Korsou FM mainly broadcasts in the local language. Papiamento is the locally spoken language on Curacao. Tune in now to enjoy the tropical vibes of this radio station. Here you will enjoy the South American hits for salsa, merengue, reggaeton, but also for other well-known latin pop hits.'
            },
           {   displayName: 'Radio Paradise 103.1 FM',
                   name: 'Radio-Paradise-103.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=PtSGTfvAvd4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://38.96.175.97:4210/;',
                   id: '312',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'The official language on Paradise FM Dutch and sometimes English is also spoken. Paradise FM is the radio station with 24 hours a day "The Sound of Curaçao"! The latest news, the best music and great prices! On frequency all over Curacao on 103.1 FM. Young people in particular and people between 30 and 55 years of age listen to the programs every day. All broadcasts are alternated with news every half hour, during which the local actuality issues are discussed. The programs start every day from 6 a.m. These can be heard until 7 p.m. There is a lot of interaction with the target group.'
            },
           {   displayName: 'HiT radio 91.5 FM',
                   name: 'HiT-radio-91.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=cdX15hdKOAs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://ocsp.comodoca.com/;',
                   id: '313',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Hitradio 915 is one of the youngest radio station of Curaçao. The music is always fun here. With this channel you can enjoy the latest musical hits. Enjoy the latest international pop hits, but also more local and South American music. The broadcast is being performed on Curaçao and can be also listened to on the 91.5 FM frequency.'
            },
           {   displayName: 'Dolfijn 97.5 FM',
                   name: 'Dolfijn-97.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=UTR1aQ_tFyg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://85.214.231.253:8004/;',
                   id: '314',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Dolfijn FM is one the largest and most popular radio channel in Curaçao. Especially among the younger population, this channel is listened to a lot. The working language on this channel is Dutch. The channel is known for broadcasting international hits. The biggest hits of Curaçao are broadcast daily. But also hits from the Netherlands, America and Latin America. can be heard here. In addition to music, you can also stay up to date with the latest news from the former Netherlands Antilles. In addition, programs are broadcast daily from 2 a.m. to 9 p.m. Time there is abbreviated as AST (Atlantic Standard Time). Listeners can participate in competitions by listening. With this they can win prizes. The prizes often consist of tickets for concerts, festivals, films, sports competitions and other events. Dolphin FM is broadcast from Willemstad Curaçao. '
            },
           {   displayName: 'Radio One  103.9 FM',
                   name: 'Radio-One-103.9-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=jy17nzx_Kek%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8004/live',
                   id: '315',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio One is a radio channel that focuses on the young people of the Caribbean island. This is reflected in the music genre that can be heard on this channel. Every day listeners can enjoy the latest dance and top 40 hits. This therefore mainly concerns the latest electro hits. So there are many famous DJs in the playlist. The slogan of this channel is the station for the next generation. Radio one Curacao was founded in 2002. The founder has estimated the music genre well. There are not many channels that focus purely on Dance and the top 40 genre. Every evening remixes are broadcast from famous DJs in the DJ show. You can tune in also via frequency 103.9 FM. In addition to broadcasts over the air, this channel is also active on social media. On the Facebook page, photos and films are shared from House and Dance parties. In addition, a live report is regularly made of festivals where the radio is present. The working language on this channel is English which connects to the younger target group.'
            },
           {   displayName: 'Easy 97.9 FM',
                   name: 'Easy-97.9-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=VOGzUdWEKvY%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8011/live',
                   id: '316',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Easy FM is a radio channel that can be received on Curaçao and Aruba via the Ether. The frequency of the channel is 97.9 FM. The channel is still growing and is doing everything it can to attract as many listeners as possible every year. That is why the channel has chosen to stream online as well as over the air. '
            },
           {   displayName: 'Radio New Song (RTNS) 96.5 FM',
                   name: 'Radio-New-Song',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=1-SwOM7w2X4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s7.voscast.com:8704/;',
                   id: '317',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio New Song (RTNS) is more than just a radio channel. It is an open community where everyone is welcome to listen. This community unites weekly, monthly, daily in the church. Christianity and the Gospel are central. In addition to radio, television is also produced by RTNS. RTNS stands for Radio Television New Song. Since 1980, this channel can be heard over the air. Thanks to the great success and many donations, they were later able to expand their services with a TV channel. The broadcasts takes place from the studio or live from the church. Live streams of meetings, prayers, festivities. Through the broadcasting creating awareness and sharing the word of God. With this they hope to grow as a community by finding more followers who follow the word of God. To listen, you can tune into the 96.5 FM frequency. The working language is Dutch, Papiamento, but sometimes English. RTNS also has a college called Skol Immanuel.'
            },
           {
                   displayName: 'Voz di Bonaire 94.7 FM',
                   name: 'Voz-di-Bonaire-94.7FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=roXCb-zX6tI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://159.203.190.86:8304/;stream.mp3',
                   id: '318',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Voz di Bonaire broadcasts'
            },
        {
                   displayName: 'Radio Antiano',
                   name: 'Radio-Antiano',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=7c0wlGWjLlA%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/radioantiano?mp=/1',
                   id: '319',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Romantic sounds',
                   name: 'Romantic-sounds',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CS6m89WOgnE%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/romanticsound?mp=/1',
                   id: '320',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Chilling Station',
                   name: 'Chilling-Staion',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=8IyQESwgyvI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mediaserv30.live-streams.nl:8090/stream',
                   id: '322',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Chillingstation ta dal Master!'
            },
           {
                   displayName: 'Bula bo Radio',
                   name: 'Bula-bo-raido',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=BFNkTi_d80A%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://109.169.23.22:12731/;stream.mp3',
                   id: '323',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Nos Nota Number 1 Pero Ta Nos Ta Esun!'
            },
       {
                   displayName: 'Comeback Radio',
                   name: 'Comback-Radio',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dqiKZKTAAi0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://74.50.122.103:7000/;stream/1',
                   id: '324',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pa amante di montuno: 24/7 dushi musika den bo orea'
            },
       {
                   displayName: 'True 4 Life',
                   name: 'True-4-life',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=KwxV1KBMjsU%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://213.202.241.199:8058/;stream/1',
                   id: '325',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Fever Pitch',
                   name: 'Fever-Pitch',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=F669NviYOhA%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mediaserv30.live-streams.nl:8097/;stream.mp3',
                   id: '325',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'ONE LOVE'
            },
           {
                   displayName: 'Voz di Bonaire 94.7 FM',
                   name: 'Voz-di-Bonaire-94.7FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=roXCb-zX6tI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://159.203.190.86:8304/;stream.mp3',
                   id: '200',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Voz di Bonaire broadcasts'
            },
           {
                   displayName: 'Mega Classics 99.5 FM',
                   name: 'Mega-Classics-99.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=jpgYi6ZvRho%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.80.21:9120/;stream/1',
                   id: '201',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Mega Classics is the sister station of the popular Mega Hit FM (101.1 Mhz) on Bonaire and started in February 2018 as an internet radio station. Here at Mega Classics you can listen to all the best Oldies FEEL THE MUSIC!'
            },
           {
                   displayName: 'Alpha 93.1 FM',
                   name: 'Alpha-93.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=o3MH6aHNRsg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s7.streammonster.com:8394/;stream/1',
                   id: '203',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'DESCRIPTON TO BE ADDED'
            },
       {
                   displayName: 'Radio Adventista Boneiru 91.1 FM',
                   name: 'Adventista-Boneiru-91.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=_3m9Czu2PJM%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://streaming.capasiete.com:7270/;stream/1',
                   id: '204',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Kompartiendo Zonido di Speransa ku henter Boneiru'
            },
       {
                   displayName: 'Live 99 FM',
                   name: 'Live-99-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=siqFrqEvClQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.80.220:8000/;stream.mp3;stream/1',
                   id: '205',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'E Radio 100% gusta i skucha!'
            },
       {
                   displayName: 'Mega Hit 101.1 FM',
                   name: 'Mega-Hit-101.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=tBIROPg70Uk%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://megaspice.primcast.com:5216/;stream/1',
                   id: '206',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Feel The Music!'
            },
       {
                   displayName: 'Radio Bon FM 102.7',
                   name: 'Radio-Bon-FM-102.7',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=AeEhRxmfQ4M%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://bonfm5.primcast.com:6482/;stream/1',
                   id: '207',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Radio Brokes 93.5 FM',
                   name: 'Radio-Brokes-93.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=16y1uTMrB-A%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://78.129.193 .82:21242/;stream/1',
                   id: '208',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: '1300 AM Voice Of St. Maarten (PJD2)',
                   name: '1300-AM-Voice-Of-St.Maarten',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=ljwHYvlLIN4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://199.189.111.28:8364/;stream/1',
                   id: '400',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'SOS Radio 95.9 FM',
                   name: 'SOS-Radio-95.9-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=hU1YbvpO4uE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://2stream.dyndns.org:8000/sosradio',
                   id: '401',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'WE ENJOY!The most listened to radio station on St.Maarten/St.Martin'
            },
       {
                   displayName: 'Oasis 96.3FM',
                   name: 'Oasis-96.3-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=_GmK3sCg2Wg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel5.mp3',
                   id: '402',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The softer side of St.Maarten'
            },
        {
                   displayName: 'Radio Pearl 98.1 FM',
                   name: 'Radio-Pearl-98.1-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=aZwszpvco00%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel6.mp3',
                   id: '403',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pearl 98.1 FM'
            },
       {
                   displayName: 'Radio Laser 101.1 FM',
                   name: 'Radio-Laser-101.1-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=5uEq0KiB9ic%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel7.mp3',
                   id: '404',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'X104.3 FM',
                   name: 'X104.3-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=5YxGiT3qbk8%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/1043.mp3',
                   id: '405',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Troppix 105.5 FM',
                   name: 'Troppix-105.5-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=QAGDc3577sk%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel2.mp3',
                   id: '406',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The sounds of the Caribbean'
            },
           {
                   displayName: 'Radio Small Island Massive',
                   name: 'Radio-Small-Island-Massive',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=vRl_8gGH-2g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://vousstream.com:8078/stream',
                   id: '407',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'Small Island with Massive Radio'
            },
       {
                   displayName: 'Hot 24/7 Sint Maarten',
                   name: 'Hot-24-7-Sint-Maarten',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=mairX8hIUKs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8095/;stream/1',
                   id: '408',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The planet\'s hottest music'
            },
           {
                   displayName: 'Gospel Beithel Online',
                   name: 'Gospel-Beithel-Online',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=qrHMYGcA464%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/radiobeithelgospel?mp=/1',
                   id: '700',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Radio reino di Dios',
                   name: 'Radio-reino-di-Dios',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=QX4gm6yGBqQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://proxy.live-streams.nl/radioreinodidios',
                   id: '701',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Gospel Star Radio',
                   name: 'Gospel-Star-Radio',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=sEMXA4Fyg_Q%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://eu5.fastcast4u.com:5093/;stream/1',
                   id: '702',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: 'Kaminda Kristu ta Señor'
            },
           ];

export const RadiosOld: Radio[] = [

       {
               displayName: 'Radio Hit 94.1 FM ',
                   name: 'Radio-Hit-94.1-FM ',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=UCB1j0R4SBs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://hit94fm.serverroom.us:8490/;stream/1',
                   id: '100',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'We blow the competition away'
            },
       {
               displayName: 'The New Cool FM 98.9',
                   name: 'The-New-Cool-FM-98.9',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=1zsjSDS-78I%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://www.coolaruba.com:8000/;stream/1',
                   id: '101',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Hits 100.9 FM',
                   name: 'Hits-100.9-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CmpaUPJ0gD0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://209.9.238.26/stream',
                   id: '102',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Power 101.7 FM',
                   name: 'Power-101.7-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zMXe4_GMNso%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.81.62:8000/;stream/1',
                   id: '103',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'Show it to me'
            },
       {
               displayName: 'Massive',
                   name: 'Massive',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zRJzeGw8T_Y%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://shoutie.bitklik.com:8001/;stream/1',
                   id: '104',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'GFM 99.9 FM',
                   name: 'GFM-99.9-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=o8FstAvlTRo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://130.185.144.199:35687/;stream/1',
                   id: '105',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Fresh Fm 104.3',
                   name: 'Fresh-Fm-104.3',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=OpkcUSmhLa0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://www.fresharuba.com:8006/;stream/1',
                   id: '106',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'I love Aruba',
                   name: 'I-love-Aruba',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=zFEfP38vZ_w%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://67.212.189.122:8119/;stream/1',
                   id: '107',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Blue FM',
                   name: 'Blue-FM',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=iNXfUyHhizY%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.245.78.146:8014/;stream/1',
                   id: '108',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Radio Mania',
                   name: 'Radio-Mania',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=B3ezWT9QgG4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://62.210.209.179:8047/live',
                   id: '109',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pegate'
            },
       {
               displayName: 'Radio 737',
                   name: 'Radio-737',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dRLsDI1zUiE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.236.59.83:8240/;stream/1',
                   id: '110',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: 'The force of music'
            },
       {
               displayName: 'VIP Radio Romance',
                   name: 'VIP-Radio-Romance',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=PI44-hvEXmE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://192.241.254.172:8501/ch5',
                   id: '111',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
               displayName: 'Vida FM Aruba',
                   name: 'Vida-FM-Aruba',
                   country: 'Aruba',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=kNchwzxwKy4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://62.210.209.179:8020/live',
                   id: '112',
                   order : 1,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
           {
               displayName: 'Radio Mas 99.7 FM',
                   name: 'Radio-Mas-99.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CznuOllfxJg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mas99.westream.cloud:8000/mas99',
                   id: '301',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Mas 99.7 FM is the most listened to channel among the young people of Curaçao. The DJs present the daily programs in a fun and modern way. Radio Mas was established in December 2001. Since then it can be received on the 99.7 FM frequency. It is a commercial radio channel that radiates positivity. In addition to broadcasting the entertaining programs, the objective is also to be educational.'
            },
           {   displayName: 'Clazz 95.1 FM',
                   name: 'Clazz-95.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=uBDc0K4xMYo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8016/live',
                   id: '302',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Clazz FM is a radio station that is located on the Curacao. From this island it broadcasts the most beautiful Jazz music over the air. In addition, blues and more exotic music are also played. You can recognize the relaxed atmosphere of radio Curaçao on this channel. Enjoy the wonderful broadcasts 24/7 every day. Clazz FMs slogan is: "Not only the good music ... Just the best!" and "The stage for Curacao s leading musicians!"'
            },
           {   displayName: 'Radio Krioyo 89.7 FM',
                   name: 'Radio-Krioyo-89.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=n9jWTqsOs0w%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://173.245.78.146:8000/;stream.mp3',
                   id: '303',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Krioyo is a favorite channel among the local listeners of 30+ years. The reason is because the tunes played varies from older exotic music to more current Latin hits. But it is clear that this channel has not forgotten its traditional hits. And that is exactly where this channel got its popularity from. Where the competitors usually go with the choice of music in contemporary pop music, Radio Krioyo continues to stick to the traditions. And that is a unique under-capital. Thanks to this channel you can still listen to the older local music. The most used language is therefore Papiamento. The language most commonly spoken on Curacao.'
            },
           {   displayName: 'Radio Direct 107.1 FM',
                   name: 'Radio-Direct-107.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=VRNlljPfQoQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s9.voscast.com:7364/;stream/1',
                   id: '304',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Are you interested in the local news of Curacao and regional exotic music? Then it is best to tune into Radio Direct 107. All of Curacao enjoys the programs that can be heard on Direct 107 FM every day. The target group of this channel are young people between 18 and 35 years old. As far as music is concerned, it is mainly exotic South American oriented. The emphasis is on Caribbean music. From zouk to salsa and dancehall ... you can hear it all on this channel. Papiamento is the most spoken language on this channel. Direct 107 FM is the radio broadcaster of Curacao. Do you like music from the former Netherlands Antilles?'
            },
           {   displayName: 'Radio Hoyer 1 101.9 FM',
                   name: 'Radio-Hoyer-1',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=y8EnEeQgeeo%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s10.voscast.com:9880/;',
                   id: '305',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Hoyer 1 has been a household name on the Caribbean islands for a long time. It has been in the top channels on the island of Curacao for years! To this day, it is a very popular channel. Especially on Curacao, where it has been established since its foundation. This channel has been around for more than half a century! It is a huge achievement that the channel has been entertaining the population for such a long time and provided them with fun, but especially useful programs. The channel was established in 1954. Radio Hoyer 1 was a fact. It could be listened through the FM and AM frequencies at the time. Currently the AM channel has been canceled and you can only listen via FM or the online webstream. The name owes the channel to the founder, Horacio Erasmo Hoyer, who started broadcasting on March 10, 1954.'
            },
           {   displayName: 'Radio Hoyer 2 105.1 FM ',
                   name: 'Radio-Hoyer-2',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=44buYZIE-lE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s10.voscast.com:9772/;',
                   id: '306',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Hoyer 2 is just as popular as Hoyer 1. “The sun is always shining!” is the slogan of this channel. The big difference is that Hoyer 2 is more internationally oriented than the Hoyer 1. Music such as Reggae, Dancehall and pop are broadcast on this channel. The history of the company behind this channel goes way back. Radio Hoyer was founded by a well-known athlete in Curaçao who’s name is Horacio Erasmo Hoyer. He started broadcasting on March 10, 1954.'
            },
           {   displayName: 'Radio Active 104.5 FM',
                   name: 'Radio-Active-104.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dtMtyZqZ1E0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://radioactive.shoutcaststream.com:8416/stream',
                   id: '307',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Active 104.5 FM Curaçao is a popular radio channel which is located on Curacao. Listening to Radio Active  you can enjoy cozy pop music with a South American touch. For example, do you like Jean Pauls music? Then you are at the right place at Radio Active. In addition to pleasant music, you can also enjoy entertaining radio programs here. By calling in you can participate in the entertaining programs. You can socialize with the presenter of the program or participate in the discussions. In addition to entertainment, the latest news can be heard during the information break. In particular, local news from the region is presented. You can listen to the news every hour during the information break. This is alternated with advertisements. “Korsou ta Dushi” is the slogan of the popular channel.'
            },
           {   displayName: 'Radio Deltha 92.7 FM',
                   name: 'Radio-Deltha-92.7-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=666f2uHXGko%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://radiodeltha927fm.primcast.com:6484/;',
                   id: '308',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Deltha FM is a radio channel situated in Willemstad Curaçao. This local radio station addresses political issues on the island and also topics related to religion, such as the Gospel. The local language Papiementu is spoken on this channel which locally is the most commonly used in addition to Dutch. Listeners can dial in over the phone to give their opinion or to participate in discussions about certain political or current issues.'
            },
           {   displayName: 'Z86 Curacao Radio',
                   name: 'Z86-Curacao-Radio',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=i1fKZ-PIPBQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://curom.cw/z86.html',
                   id: '309',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Z86 is a radio station that has been around for more than 80 years.  On the island of Curaçao you can receive this channel on the frequency 860 AM. Radio station Z86 has been broadcasting on this frequency since 1933. This makes it the oldest channel in Curaçao. The parent company of the channel has Curom Broadcasting. That is a media company that has multiple radio channels on Curaçao. Z86 is known for the mission that is different from the competition. The entire radio programming of this radio station is based on informing the local population. Radio Z86 AM is mainly known for the interesting talk shows addressing local or international news as the main subject. In addition to news, topics such as sports and religion are also discussed.'
            },
           {   displayName: 'Radio Korsou  94.5 FM',
                   name: 'Radio-Korsou-94.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=yhk83vLOx_g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.243.94.61:8028/;',
                   id: '310',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Korsou is the voice of the inhabitants of the most beautiful island in the world. On Curacao you can listen to this channel live every day via the ether and the online live stream. You can tell from the name that Radio Korsou FM mainly broadcasts in the local language. Papiamento is the locally spoken language on Curacao. Tune in now to enjoy the tropical vibes of this radio station. Here you will enjoy the South American hits for salsa, merengue, reggaeton, but also for other well-known latin pop hits.'
            },
           {   displayName: 'Radio Korsou  94.5 FM',
                   name: 'Radio-Korsou-94.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=yhk83vLOx_g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.243.94.61:8028/;',
                   id: '311',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Korsou is the voice of the inhabitants of the most beautiful island in the world. On Curacao you can listen to this channel live every day via the ether and the online live stream. You can tell from the name that Radio Korsou FM mainly broadcasts in the local language. Papiamento is the locally spoken language on Curacao. Tune in now to enjoy the tropical vibes of this radio station. Here you will enjoy the South American hits for salsa, merengue, reggaeton, but also for other well-known latin pop hits.'
            },
           {   displayName: 'Radio Paradise 103.1 FM',
                   name: 'Radio-Paradise-103.1-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=PtSGTfvAvd4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://38.96.175.97:4210/;',
                   id: '312',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'The official language on Paradise FM Dutch and sometimes English is also spoken. Paradise FM is the radio station with 24 hours a day "The Sound of Curaçao"! The latest news, the best music and great prices! On frequency all over Curacao on 103.1 FM. Young people in particular and people between 30 and 55 years of age listen to the programs every day. All broadcasts are alternated with news every half hour, during which the local actuality issues are discussed. The programs start every day from 6 a.m. These can be heard until 7 p.m. There is a lot of interaction with the target group.'
            },
           {   displayName: 'HiT radio 91.5 FM',
                   name: 'HiT-radio-91.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=cdX15hdKOAs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://ocsp.comodoca.com/;',
                   id: '313',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Hitradio 915 is one of the youngest radio station of Curaçao. The music is always fun here. With this channel you can enjoy the latest musical hits. Enjoy the latest international pop hits, but also more local and South American music. The broadcast is being performed on Curaçao and can be also listened to on the 91.5 FM frequency.'
            },
           {   displayName: 'Dolfijn 97.5 FM',
                   name: 'Dolfijn-97.5-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=UTR1aQ_tFyg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://85.214.231.253:8004/;',
                   id: '314',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio Dolfijn FM is one the largest and most popular radio channel in Curaçao. Especially among the younger population, this channel is listened to a lot. The working language on this channel is Dutch. The channel is known for broadcasting international hits. The biggest hits of Curaçao are broadcast daily. But also hits from the Netherlands, America and Latin America. can be heard here. In addition to music, you can also stay up to date with the latest news from the former Netherlands Antilles. In addition, programs are broadcast daily from 2 a.m. to 9 p.m. Time there is abbreviated as AST (Atlantic Standard Time). Listeners can participate in competitions by listening. With this they can win prizes. The prizes often consist of tickets for concerts, festivals, films, sports competitions and other events. Dolphin FM is broadcast from Willemstad Curaçao. '
            },
           {   displayName: 'Radio One  103.9 FM',
                   name: 'Radio-One-103.9-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=jy17nzx_Kek%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8004/live',
                   id: '315',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio One is a radio channel that focuses on the young people of the Caribbean island. This is reflected in the music genre that can be heard on this channel. Every day listeners can enjoy the latest dance and top 40 hits. This therefore mainly concerns the latest electro hits. So there are many famous DJs in the playlist. The slogan of this channel is the station for the next generation. Radio one Curacao was founded in 2002. The founder has estimated the music genre well. There are not many channels that focus purely on Dance and the top 40 genre. Every evening remixes are broadcast from famous DJs in the DJ show. You can tune in also via frequency 103.9 FM. In addition to broadcasts over the air, this channel is also active on social media. On the Facebook page, photos and films are shared from House and Dance parties. In addition, a live report is regularly made of festivals where the radio is present. The working language on this channel is English which connects to the younger target group.'
            },
           {   displayName: 'Easy 97.9 FM',
                   name: 'Easy-97.9-FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=VOGzUdWEKvY%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8011/live',
                   id: '316',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Easy FM is a radio channel that can be received on Curaçao and Aruba via the Ether. The frequency of the channel is 97.9 FM. The channel is still growing and is doing everything it can to attract as many listeners as possible every year. That is why the channel has chosen to stream online as well as over the air. '
            },
           {   displayName: 'Radio New Song (RTNS) 96.5 FM',
                   name: 'Radio-New-Song',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=1-SwOM7w2X4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s7.voscast.com:8704/;',
                   id: '317',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Radio New Song (RTNS) is more than just a radio channel. It is an open community where everyone is welcome to listen. This community unites weekly, monthly, daily in the church. Christianity and the Gospel are central. In addition to radio, television is also produced by RTNS. RTNS stands for Radio Television New Song. Since 1980, this channel can be heard over the air. Thanks to the great success and many donations, they were later able to expand their services with a TV channel. The broadcasts takes place from the studio or live from the church. Live streams of meetings, prayers, festivities. Through the broadcasting creating awareness and sharing the word of God. With this they hope to grow as a community by finding more followers who follow the word of God. To listen, you can tune into the 96.5 FM frequency. The working language is Dutch, Papiamento, but sometimes English. RTNS also has a college called Skol Immanuel.'
            },
           {
                   displayName: 'Voz di Bonaire 94.7 FM',
                   name: 'Voz-di-Bonaire-94.7FM',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=roXCb-zX6tI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://159.203.190.86:8304/;stream.mp3',
                   id: '318',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Voz di Bonaire broadcasts'
            },
        {
                   displayName: 'Radio Antiano',
                   name: 'Radio-Antiano',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=7c0wlGWjLlA%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/radioantiano?mp=/1',
                   id: '319',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Romantic sounds',
                   name: 'Romantic-sounds',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=CS6m89WOgnE%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/romanticsound?mp=/1',
                   id: '320',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Chilling Station',
                   name: 'Chilling-Staion',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=8IyQESwgyvI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mediaserv30.live-streams.nl:8090/stream',
                   id: '322',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Chillingstation ta dal Master!'
            },
           {
                   displayName: 'Bula bo Radio',
                   name: 'Bula-bo-raido',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=BFNkTi_d80A%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://109.169.23.22:12731/;stream.mp3',
                   id: '323',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Nos Nota Number 1 Pero Ta Nos Ta Esun!'
            },
       {
                   displayName: 'Comeback Radio',
                   name: 'Comback-Radio',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=dqiKZKTAAi0%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://74.50.122.103:7000/;stream/1',
                   id: '324',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pa amante di montuno: 24/7 dushi musika den bo orea'
            },
       {
                   displayName: 'True 4 Life',
                   name: 'True-4-life',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=KwxV1KBMjsU%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://213.202.241.199:8058/;stream/1',
                   id: '325',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Fever Pitch',
                   name: 'Fever-Pitch',
                   country: 'Curacao',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=F669NviYOhA%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://mediaserv30.live-streams.nl:8097/;stream.mp3',
                   id: '325',
                   order : 3,
                   // tslint:disable-next-line:max-line-length
                   description: 'ONE LOVE'
            },
           {
                   displayName: 'Voz di Bonaire 94.7 FM',
                   name: 'Voz-di-Bonaire-94.7FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=roXCb-zX6tI%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://159.203.190.86:8304/;stream.mp3',
                   id: '200',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Voz di Bonaire broadcasts'
            },
           {
                   displayName: 'Mega Classics 99.5 FM',
                   name: 'Mega-Classics-99.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=jpgYi6ZvRho%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.80.21:9120/;stream/1',
                   id: '201',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Mega Classics is the sister station of the popular Mega Hit FM (101.1 Mhz) on Bonaire and started in February 2018 as an internet radio station. Here at Mega Classics you can listen to all the best Oldies FEEL THE MUSIC!'
            },
           {
                   displayName: 'Alpha 93.1 FM',
                   name: 'Alpha-93.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=o3MH6aHNRsg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://s7.streammonster.com:8394/;stream/1',
                   id: '203',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'DESCRIPTON TO BE ADDED'
            },
       {
                   displayName: 'Radio Adventista Boneiru 91.1 FM',
                   name: 'Adventista-Boneiru-91.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=_3m9Czu2PJM%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://streaming.capasiete.com:7270/;stream/1',
                   id: '204',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Kompartiendo Zonido di Speransa ku henter Boneiru'
            },
       {
                   displayName: 'Live 99 FM',
                   name: 'Live-99-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=siqFrqEvClQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://162.244.80.220:8000/;stream.mp3;stream/1',
                   id: '205',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'E Radio 100% gusta i skucha!'
            },
       {
                   displayName: 'Mega Hit 101.1 FM',
                   name: 'Mega-Hit-101.1-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=tBIROPg70Uk%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://megaspice.primcast.com:5216/;stream/1',
                   id: '206',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: 'Feel The Music!'
            },
       {
                   displayName: 'Radio Bon FM 102.7',
                   name: 'Radio-Bon-FM-102.7',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=AeEhRxmfQ4M%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://bonfm5.primcast.com:6482/;stream/1',
                   id: '207',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Radio Brokes 93.5 FM',
                   name: 'Radio-Brokes-93.5-FM',
                   country: 'Bonaire',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=16y1uTMrB-A%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://78.129.193 .82:21242/;stream/1',
                   id: '208',
                   order : 2,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: '1300 AM Voice Of St. Maarten (PJD2)',
                   name: '1300-AM-Voice-Of-St.Maarten',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=ljwHYvlLIN4%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://199.189.111.28:8364/;stream/1',
                   id: '400',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'SOS Radio 95.9 FM',
                   name: 'SOS-Radio-95.9-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=hU1YbvpO4uE%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://2stream.dyndns.org:8000/sosradio',
                   id: '401',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'WE ENJOY!The most listened to radio station on St.Maarten/St.Martin'
            },
       {
                   displayName: 'Oasis 96.3FM',
                   name: 'Oasis-96.3-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=_GmK3sCg2Wg%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel5.mp3',
                   id: '402',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The softer side of St.Maarten'
            },
        {
                   displayName: 'Radio Pearl 98.1 FM',
                   name: 'Radio-Pearl-98.1-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=aZwszpvco00%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel6.mp3',
                   id: '403',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'Pearl 98.1 FM'
            },
       {
                   displayName: 'Radio Laser 101.1 FM',
                   name: 'Radio-Laser-101.1-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=5uEq0KiB9ic%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel7.mp3',
                   id: '404',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'X104.3 FM',
                   name: 'X104.3-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=5YxGiT3qbk8%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/1043.mp3',
                   id: '405',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
       {
                   displayName: 'Troppix 105.5 FM',
                   name: 'Troppix-105.5-FM',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=QAGDc3577sk%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://stream.sxmradio.com:8060/channel2.mp3',
                   id: '406',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The sounds of the Caribbean'
            },
           {
                   displayName: 'Radio Small Island Massive',
                   name: 'Radio-Small-Island-Massive',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=vRl_8gGH-2g%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://vousstream.com:8078/stream',
                   id: '407',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'Small Island with Massive Radio'
            },
       {
                   displayName: 'Hot 24/7 Sint Maarten',
                   name: 'Hot-24-7-Sint-Maarten',
                   country: 'St.Maarten',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=mairX8hIUKs%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://64.150.176.42:8095/;stream/1',
                   id: '408',
                   order : 4,
                   // tslint:disable-next-line:max-line-length
                   description: 'The planet\'s hottest music'
            },
           {
                   displayName: 'Gospel Beithel Online',
                   name: 'Gospel-Beithel-Online',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=qrHMYGcA464%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://eu8.fastcast4u.com/proxy/radiobeithelgospel?mp=/1',
                   id: '700',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
        {
                   displayName: 'Radio reino di Dios',
                   name: 'Radio-reino-di-Dios',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=QX4gm6yGBqQ%3d&portalid=0',
                   image: null,
                   streamUrl: 'https://proxy.live-streams.nl/radioreinodidios',
                   id: '701',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: ''
            },
         {
                   displayName: 'Gospel Star Radio',
                   name: 'Gospel-Star-Radio',
                   country: 'Gospel',
                   urlLogo: 'https://basilachill.eu/LinkClick.aspx?fileticket=sEMXA4Fyg_Q%3d&portalid=0',
                   image: null,
                   streamUrl: 'http://eu5.fastcast4u.com:5093/;stream/1',
                   id: '702',
                   order : 7,
                   // tslint:disable-next-line:max-line-length
                   description: 'Kaminda Kristu ta Señor'
            },
           ];




