import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Radio } from '../../models/radio';
import { Radios } from '../../data/radios';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Feed } from '../../models/feed';
import { HttpClient } from '@angular/common/http';
import {} from '@angular/material/progress-bar';




@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css']
})
export class RadioComponent implements OnInit {
  radioCollection = [] ;
  currentRadio = new Radio();
  currentIndex = 0;
  showNews = false;
  data = null;
  selected = 'All';
  feeds: Feed[] = [];
  bonaireFeedContent = null;
  loader = 'determinate';

  countries = [];
  displayedColumns: string[] = ['symbol', 'urlLogo',  'name'];
  dataSource = Radios;
  @ViewChild('videoPlayer') videoplayer: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected http: HttpClient,
    @Inject(DOCUMENT) private document: any
    ) {
     // super();
  }


  ngOnInit() {
    this.radioCollection = Radios;
    const radioParam = this.route.snapshot.queryParams;
    if (this.route.snapshot.params.id === undefined && this.route.snapshot.params.id !== -1) {
      this.currentRadio = this.radioCollection[this.currentIndex + 1];
      } else {
        if (this.route.snapshot.params.id !== '-1') {
         this.setRadio(this.route.snapshot.params.id);
        }
      }

      if (radioParam.radio !== '') {
        this.setRadio(radioParam.radio);
      }
      if (this.route.snapshot.params.id === '-1' ) {
       this.showNews = true;
       // tslint:disable-next-line:max-line-length
       const res =  this.http.get('https://cors-anywhere.herokuapp.com/' + 'https://masnoticia.com/feed/', {responseType: 'text'}).subscribe(
           response => {
       this.data = response;
       this.readXml(response);
       
  },
    error => {
      //this.loading = false;
   });
      }
      this.videoplayer.nativeElement.load();
      this.videoplayer.nativeElement.play();
      this.loader = 'query';
    //  this.loader = 'determinate';
  }

  setCountries() {
    this.countries.push('Aruba');
    this.countries.push('Bonaire');
    this.countries.push('Curacao');
  }

  setNewsInfo() {
    this.loader = 'quey';
    this.feeds.length = 0;
    this.showNews = true;
       // tslint:disable-next-line:max-line-length
       const res =  this.http.get('https://cors-anywhere.herokuapp.com/' + 'https://masnoticia.com/feed/', {responseType: 'text'}).subscribe(
           response => {
       this.data = response;
       this.readXml(response);
   });
   // tslint:disable-next-line:max-line-length http://feeds.feedburner.com/bonaire/MXRm/ https://bonaire.nu/feed
   const res1 =  this.http.get('https://cors-anywhere.herokuapp.com/' + 'https://bonaire.nu/feed', {responseType: 'text'}).subscribe(
    response => {
   this.data = response;
   this.readXmlBonaire(response);
   //this.loader = 'determinate';
    });
  }

  setRadioInfo() {
    this.showNews = false;
  }

  next() {
    if   (this.radioCollection.length >=  this.currentIndex ) {
    this.currentIndex = this.currentIndex + 1;
    this.currentRadio = this.radioCollection[this.currentIndex];
    this.setRadio(this.currentRadio.id);
    this.videoplayer.nativeElement.load();
    this.videoplayer.nativeElement.play();
    this.loader = 'query';
    }
  }

  prev() {
    if (this.currentIndex >= 0) {
    this.currentIndex = this.currentIndex - 1;
    this.currentRadio = this.radioCollection[this.currentIndex];
    this.setRadio(this.currentRadio.id);
    this.videoplayer.nativeElement.load();
    this.videoplayer.nativeElement.play();
    this.loader = 'query';
    }
  }

  filterCountry(res: any) {
  try {

   if (res.value !== 'All') {
    const result = Radios.filter(radio => radio.country ===  res.value );
    this.dataSource = result;
    this.radioCollection = result;
   } else {
    this.dataSource = Radios;
    this.radioCollection = Radios;
   }
  } catch  {
      //result = e.Message;
  }
  }

  readXml(res: string) {
    const result =  res.split('<title>');

    let i = 0;
    for (let j = 0; j < result.length; j++) {
      if (j > 1 && j < 10) {
        let pubDateSplit = [];
       const result2 = result[j].split('</title>');
       const pubDate = result[j].split('<pubDate>');
       if (pubDate.length > 1) {
       pubDateSplit = pubDate[1].split('</pubDate>');
       }
       let linkSplit = [];
       const link = result[j].split('<link>');
       if (link.length > 1) {
        linkSplit = link[1].split('</link>');
       }
       const newFeed = new Feed();
       newFeed.title = result2[0];
       newFeed.id = j.toString();
       if (pubDateSplit.length > 1) {
       newFeed.date = pubDateSplit[0].replace('+0000', '');
       }
       newFeed.link = linkSplit[0];
       i = i + 1;
       this.feeds.push(newFeed);
      }
    }
   }

   readXmlBonaire(res: string) {
    const currentDate = new Date;
    let dateResult = currentDate.toDateString();
    const result =  res.split('<li>');
    const dateFeeds = res.split('<h5 class="itemposttime">');
    // const dateFeeds2 = dateFeeds.split('</h5>');
    if (dateFeeds.length > 1) {
     const resDate = dateFeeds[1].split('</h5>');
     dateResult = resDate[0].replace('<span>Posted:', '');
     dateResult = dateResult.replace('</span>', '');
     dateResult = dateResult.replace('+0000', '');

    }
    this.bonaireFeedContent =  result[2];
    const newFeedTitle = new Feed();
    newFeedTitle.link = 'https://bonaire.nu/feed';
    newFeedTitle.title = 'bonaire.nu';
    newFeedTitle.id = '0';
    this.feeds.push(newFeedTitle);
    for (let j = 0; j < result.length; j++) {
      if (j > 1 && j < 10) {
      const result2 = result[j].split('<a href');
      if ( result2.length > 1) {
       const result3 = result2[1].split('/">');
       if (result3.length > 1) {
        const newFeed = new Feed();
        newFeed.link = result3[0].replace('="', ' ').trim();
        newFeed.title = result3[1].replace('</a></li>', '');
        if (newFeed.title.length > 100) {
          newFeed.title = newFeed.title.substring(0, 100) + '...';
        }
        newFeed.id = j.toString();
        newFeed.date = dateResult;
        this.feeds.push(newFeed);
       }
      }
      }
    }
   }

   stopPlayer() {
    this.loader = 'determinate';
   }
   

  setRadio(id: string) {
    for (let index = 0; index < this.radioCollection.length; index++) {
      const element = this.radioCollection[index];
      if (element.id === id) {
       this.currentRadio = this.radioCollection[index];
       this.currentIndex = index;
      } else {
        if (element.name === id ) {
          this.currentRadio = this.radioCollection[index];
          this.currentIndex = index;
        }
      }
    }
    this.videoplayer.nativeElement.load();
    this.videoplayer.nativeElement.play();
    this.loader = 'query';
  }

}
