import { Component } from '@angular/core';
import { Radio } from './models/radio';
import { Radios } from './data/radios';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})



@Component({
  selector: '',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent {
  // private route: ActivatedRoute;
 // private router: Router;
  public title = 'Radio';
  public radio = true;
  public tv = false;
  public podcast = false;
  public news = false;
  radioCollection = [] ;
  currentRadio = new Radio();
  currentIndex = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    ) {
     // super();
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    
  }

  tabEvent(event) {
    console.log(event);
    this.radio = false;
    this.tv = false;
    this.podcast = false;
    this.news = false;
    if(event==='Radio') {
     this.radio = true;
    }
    if(event=="TV")
      this.tv=true;
    if(event=="Podcast")
      this.podcast=true;
    if(event=="News")
      this.news =true;
  }

}