import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyNavComponent } from './my-nav/my-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatInputModule, MatTabsModule, MatToolbarModule} from '@angular/material';
import { MatButtonModule, MatSelectModule, MatSidenavModule, MatPaginatorModule, MatIconModule, MatTableModule} from '@angular/material';
import { MatListModule,  } from '@angular/material/list';
import { TabComponent } from './tab/tab.component';
import { HomeComponent } from './components/home/home.component';
import { RadioComponent } from './components/radio/radio.component';
import { TVComponent } from './components/tv/tv.component';
import { PodcastComponent } from './components/podcast/podcast.component';
import { NewsComponent } from './components/news/news.component';



@NgModule({
  declarations: [
    AppComponent,
    MyNavComponent,
    TabComponent,
    HomeComponent,
    RadioComponent,
    TVComponent,
    PodcastComponent,
    NewsComponent,
   // MatInputModule
  ],
  exports: [ MatListModule, MatInputModule, FormsModule ],
  imports: [
    FormsModule,
    ReactiveFormsModule,

    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatTableModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    HttpClientModule,
    MatSelectModule,
    MatInputModule,
    MatProgressBarModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
